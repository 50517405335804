import { Link } from "react-router-dom";

export interface BreadcrumbProps {
    items: { label: string, path?: string }[];
};

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
    return <nav className="mb-5">
        <ol className="breadcrumb">
            {
                items.map((x, i) => {
                    if (x.path) {
                        return <li className="breadcrumb-item" key={i}>
                            <Link to={x.path}>{x.label}</Link>
                        </li>
                    } else {
                        return <li className="breadcrumb-item active" key={i}>{x.label}</li>
                    }
                })
            }
        </ol>
    </nav>;
};