export interface ProgressMeterProps {
    percentage: number;
    label?: number;
}

export const ProgressMeter = ({ percentage, label }: ProgressMeterProps) => {
    percentage = Math.round(percentage * 100);
    return <div className="progress bg-secondary" role="progressbar">
        <div className="progress-bar" style={{ width: `${percentage}%` }}>{`${label || ""}`}</div>
    </div>
}