import axios from "axios";
import { Credentials, AssetNote, Project, ProjectIntake, ProjectResource, QuestionSet, QuestionnaireEntry, User, AssetNoteResponseAction, Questionnaire, NewUserFromInvite, AuthResult, AssetStatusChange, AssetStatusChangeType, UserInvitation, AssetType, Asset, QuestionnaireType, ProjectTemplate, PasswordUpdate, Material, MaterialSheet } from "./models";
import { FileValue } from "./components";

// Config

export const getConfiguration = async () =>
    (await axios.get<any>(`configuration`))?.data;

// Login

export const login = async (credentials: Credentials) =>
    (await axios.post<AuthResult>(`auth/login`, credentials))?.data;

// Users

export const getCurrentUser = async () =>
    (await axios.get<User>(`users/current`))?.data;

export const getUserInvitation = async (code: string) =>
    (await axios.get<UserInvitation>(`users/invitations/${code}`))?.data;

export const createUserFromInvitation = async (request: NewUserFromInvite) =>
    (await axios.post<User>(`users`, request))?.data;

export const forgotPassword = async (email: string) =>
    (await axios.post<void>(`users/forgot-password`, { email }))?.data;

export const updatePassword = async (passwordUpate: PasswordUpdate) =>
    (await axios.post<void>(`users/password-update`, passwordUpate))?.data;

// ProjectTemplates

export const listProjectTemplates = async (params?: any) =>
    (await axios.get<ProjectTemplate[]>(`project-templates`, { params }))?.data;

export const getProjectTemplate = async (projectTemplateId: string) =>
    (await axios.get<ProjectTemplate>(`project-templates/${projectTemplateId}`))?.data;

export const saveProjectTemplate = async (projectTemplate: ProjectTemplate) =>
    (await axios.post<ProjectTemplate>(`project-templates`, projectTemplate))?.data;

export const deleteProjectTemplate = async (projectTemplateId: string) =>
    (await axios.delete<void>(`project-templates/${projectTemplateId}`))?.data;

// QuestionSets

export const listQuestionSets = async (params?: any) =>
    (await axios.get<QuestionSet[]>(`question-sets`, { params }))?.data;

export const getQuestionSet = async (questionSetId: string) =>
    (await axios.get<QuestionSet>(`question-sets/${questionSetId}`))?.data;

export const saveQuestionSet = async (questionSet: QuestionSet) =>
    (await axios.post<QuestionSet>(`question-sets`, questionSet))?.data;

export const deleteQuestionSet = async (questionSetId: string) =>
    (await axios.delete<void>(`question-sets/${questionSetId}`))?.data;

export const reorderQuestionSets = async (questionSetIds: string[]) =>
    (await axios.patch<QuestionSet[]>(`question-sets/sorting`, questionSetIds))?.data;

// Intake

export const listProjectIntakes = async (params?: any) =>
    (await axios.get<ProjectIntake[]>(`projects/intake`, { params }))?.data;

export const getProjectIntake = async (intakeId: string) =>
    (await axios.get<ProjectIntake>(`projects/intake/${intakeId}`))?.data;

export const saveProjectIntake = async (intake: ProjectIntake) =>
    (await axios.post<ProjectIntake>(`projects/intake`, intake))?.data;

export const deleteProjectIntake = async (intakeId: string) =>
    (await axios.delete<ProjectIntake>(`projects/intake/${intakeId}`))?.data;

export const convertIntakeToProject = async (intakeId: string, projectId: string) =>
    (await axios.patch<Project>(`projects/intake/${intakeId}/convert`, { projectId }))?.data;


// Projects

export const listProjects = async (params?: any) =>
    (await axios.get<Project[]>(`projects`, { params }))?.data;

export const getProject = async (id: string) =>
    (await axios.get<Project>(`projects/${id}`))?.data;

export const saveProject = async (project: Project) =>
    (await axios.post<Project>(`projects`, project))?.data;

export const cancelProject = async (id: string, reason: string) =>
    (await axios.patch<Project>(`projects/${id}/cancel`, { reason }))?.data;

export const completeProject = async (id: string) =>
    (await axios.patch<Project>(`projects/${id}/complete`))?.data;

export const reopenProject = async (id: string) =>
    (await axios.patch<Project>(`projects/${id}/reopen`))?.data;

export const deleteProject = async (projectKey: string) =>
    (await axios.delete<void>(`projects/${projectKey}`))?.data;

export const forceProjectSync = async (id: string) =>
    (await axios.post<void>(`projects/${id}/sync`))?.data;

// Questionnaires

export const getQuestionnaire = async (questionnaireId: string) =>
    (await axios.get<Questionnaire>(`questionnaires/${questionnaireId}`))?.data;

export const saveQuestionnaireEntry = async (questionnaireId: string, entry: QuestionnaireEntry) =>
    (await axios.post<QuestionnaireEntry>(`questionnaires/${questionnaireId}/entries`, entry))?.data;

export const revertPendingQuestionnaireEdits = async (questionnaireId: string) =>
    (await axios.delete<Questionnaire>(`questionnaires/${questionnaireId}/entries`))?.data;

export const saveQuestionnaireSubmission = async (questionnaireId: string, submission: { notes?: string }) =>
    (await axios.post<Questionnaire>(`questionnaires/${questionnaireId}/submit`, submission))?.data;

// Asssets

export const getProjectAssets = async (projectId: string, type?: AssetType) =>
    (await axios.get<Asset[]>(`projects/${projectId}/assets`, { params: { type } }))?.data;

export const addAssetNote = async (projectId: string, assetVersionId: string, data: { text: string }) =>
    (await axios.post<AssetNote>(`projects/${projectId}/assets/${assetVersionId}/notes`, data))?.data;

export const deleteAssetNote = async (projectId: string, assetVersionId: string, noteId: string) =>
    (await axios.delete<void>(`projects/${projectId}/assets/${assetVersionId}/notes/${noteId}`))?.data;

export const addAssetNoteResponse = async (projectId: string, assetVersionId: string, noteId: string, data: { text?: string, action?: AssetNoteResponseAction }) =>
    (await axios.post<AssetNote>(`projects/${projectId}/assets/${assetVersionId}/notes/${noteId}/responses`, data))?.data;

export const deleteAssetNoteResponse = async (projectId: string, assetVersionId: string, noteId: string, responseId: string) =>
    (await axios.delete<void>(`projects/${projectId}/assets/${assetVersionId}/notes/${noteId}/responses/${responseId}`))?.data;

export const saveAssetStatusChange = async (projectId: string, assetVersionId: string, data: { type: AssetStatusChangeType, notes?: string }) =>
    (await axios.post<AssetStatusChange>(`projects/${projectId}/assets/${assetVersionId}/status-changes`, data))?.data;

// Resources

export const getProjectFileFolder = async (projectId: string, folderId?: string) =>
    (await axios.get<ProjectResource>(`projects/${projectId}/files`, folderId ? { params: { folderId } } : undefined))?.data;

export const addProjectFilesFolder = async (projectId: string, folder: { name: string }, folderId?: string) =>
    (await axios.post<ProjectResource>(`projects/${projectId}/folders`, folder, folderId ? { params: { folderId } } : undefined))?.data;

export const uploadProjectFiles = async (projectId: string, files: FileValue[], folderId?: string) =>
    (await axios.post<ProjectResource[]>(`projects/${projectId}/files`, files, folderId ? { params: { folderId } } : undefined))?.data;

export const deleteProjectFile = async (projectId: string, fileId: string) =>
    (await axios.delete<void>(`projects/${projectId}/files/${fileId}`))?.data;

export const moveOrRenameProjectFile = async (projectId: string, fileId: string, file: { name?: string, folderId?: string }) =>
    (await axios.patch<ProjectResource>(`projects/${projectId}/files/${fileId}`, file))?.data;

// Material Sheets

export const listMaterialSheets = async (params?: any) =>
    (await axios.get<MaterialSheet[]>(`material-sheets`, { params }))?.data;

export const getMaterialSheet = async (materialSheetId: string) =>
    (await axios.get<MaterialSheet>(`material-sheets/${materialSheetId}`))?.data;

export const saveMaterialSheet = async (materialSheet: any) =>
    (await axios.post<MaterialSheet>(`material-sheets`, materialSheet))?.data;

export const deleteMaterialSheet = async (projectId: string) =>
    (await axios.delete<void>(`material-sheets/${projectId}`))?.data;

export const submitMaterialSheet = async (materialSheetId: string, submission: { notes?: string }) =>
    (await axios.post<MaterialSheet>(`material-sheets/${materialSheetId}/submit`, submission))?.data;

// Materials

export const listMaterialCategories = async () =>
    (await axios.get<Material[]>(`materials/categories`))?.data;

export const listMaterials = async (params?: any) =>
    (await axios.get<Material[]>(`materials`, { params }))?.data;

export const getMaterial = async (materialId: string) =>
    (await axios.get<Material>(`materials/${materialId}`))?.data;

export const saveMaterial = async (material: any) =>
    (await axios.post<Material>(`materials`, material))?.data;

export const deleteMaterial = async (materialId: string) =>
    (await axios.delete<void>(`materials/${materialId}`))?.data;
