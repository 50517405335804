import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getProject, getProjectAssets, listMaterialSheets, submitMaterialSheet } from "../../../api";
import { useAppContext, useDialog } from "../../../hooks";
import { Asset, MaterialSheet, Project } from "../../../models";

import { printRelativeDate } from "../../../util";
import { Blocker, Breadcrumb, Card } from "../../common";
import './materialSheetList.scss';

export const MaterialSheetList = () => {
    const { projectKey } = useParams();
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [designs, setDesigns] = useState<Asset[]>();
    const [materialSheets, setMaterialSheets] = useState<MaterialSheet[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { confirm, prompt } = useDialog();

    const { user } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.roles.includes('admin')) {
            navigate('/', { replace: true });
        }
    }, []);

    useEffect(() => {
        getProject(projectKey!).then(project => {
            if (project) {
                setProject(project);
                getProjectAssets(project.id, "Design").then(designs => setDesigns(designs));
                listMaterialSheets({ projectId: project.id }).then(materialSheets => {
                    if (materialSheets) {
                        setMaterialSheets(materialSheets);
                    }
                });
            }
        });
    }, [projectKey]);

    const onSubmit = async (materialSheetId: string) => {
        if (await confirm('Are you sure you want to submit this material sheet?')) {
            const notes = await prompt({
                message: 'Enter any notes for the submission (optional):',
                multiline: true
            });

            setIsSubmitting(true);
            submitMaterialSheet(materialSheetId, { notes }).then(materialSheet => {
                if (materialSheet) {
                    setMaterialSheets(materialSheets.map(x => x.id === materialSheetId ? materialSheet : x));
                };
                setIsSubmitting(false);
            });
        }
    };

    if (!project || !materialSheets) return <div>Loading Material Sheets...</div>;

    return <div>
        <Blocker block={isSubmitting} />
        <div>
            <div className='float-end'>
                <button type="button"
                    onClick={() => navigate('/admin/projects')}
                    className='btn btn-secondary ms-2'>
                    Cancel
                </button>

                <button type="button"
                    onClick={() => navigate(`/admin/projects/${project.key}/material-sheets/new`)}
                    className='btn btn-primary ms-2'>
                    New Material Sheet
                </button>
            </div>
            <Breadcrumb items={[
                { label: 'Projects', path: '/admin/projects' },
                { label: project.address.street, path: `/admin/projects/${project.key}` },
                { label: 'Material Sheets' }
            ]} />
            <div className='clearfix'></div>
        </div>
        <div className="row">
            {
                !materialSheets.length &&
                <div className="col-md-12 ms-0">
                    <div className="text-muted text-center mt-5">
                        No material sheets have been created for this project.
                    </div>
                </div>
            }
            {
                materialSheets.map(materialSheet => {
                    const lastSubmission = materialSheet.submissions?.length ? materialSheet.submissions[materialSheet.submissions.length - 1] : undefined;

                    return <div key={materialSheet.id}
                        className="col-md-4">
                        <Card>
                            <div className="float-end">
                                <span className={`badge ${materialSheet.status === 'Draft' ? "text-bg-success" : "text-bg-dark"}`}>
                                    {materialSheet.status}
                                </span>
                            </div>
                            <h3 className="mb-4">
                                {materialSheet.title}
                            </h3>
                            {
                                materialSheet.designAssetId &&
                                <p>
                                    Design: {designs?.find(d => d.id === materialSheet.designAssetId)?.versions[0].label}
                                </p>
                            }
                            <hr />
                            {
                                lastSubmission &&
                                <div className="box bg-light mt-3 mb-3">
                                    <div className="text-muted text-sm float-end">
                                        Submitted: {printRelativeDate(lastSubmission.submittedOn)}<br />
                                        {lastSubmission.submittedBy}
                                    </div>
                                    <h5>
                                        <i className="fa-regular fa-file me-2"></i>
                                        <a href={lastSubmission.webUrl} target="_blank" rel="noreferrer">
                                            Version {lastSubmission.version}
                                        </a>
                                    </h5>
                                    <div className="clearfix"></div>
                                </div>
                            }
                            <div className="float-end">
                                <button className="btn btn-outline-secondary btn-sm me-2"
                                    onClick={() => onSubmit(materialSheet.id)}
                                    disabled={materialSheet.status === 'Submitted'}>
                                    {lastSubmission ? 'Resubmit' : 'Submit'}
                                </button>
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => navigate(`/admin/projects/${project.key}/material-sheets/${materialSheet.id}`)}>
                                    Edit
                                </button>
                            </div>
                            {
                                materialSheet.lastUpdatedOn && materialSheet.lastUpdatedBy &&
                                <div className="text-muted text-sm">
                                    Last updated: {printRelativeDate(materialSheet.lastUpdatedOn)}<br />
                                    {materialSheet.lastUpdatedBy}
                                </div>
                            }
                            <div className="clearfix"></div>
                        </Card>
                        <div className="clearfix"></div>
                    </div>;
                })
            }
        </div>
    </div>;
};