import { generateUUID } from "../../../util";
import { FormOption } from "../models";
import './checkboxListDropdown.scss';

export interface CheckboxListDropdownProps {
    label: string
    options: FormOption[];
    values: string[];
    onChange: (values: string[]) => void;
    columns?: 1 | 2 | 3;
}

export const CheckboxListDropdown = ({ label, options, values, onChange, columns = 1 }: CheckboxListDropdownProps) => {
    const id = generateUUID();

    const displayValues = options?.filter(x => values?.includes(x.value)).map(x => x.label) || [];

    return <div className='form-group checkbox-list-dropdown'>
        <label htmlFor={id}>
            {label}
        </label>
        <div className='dropdown'>
            <button className='form-control dropdown-toggle text-start non-button'
                id={id}
                type='button'
                data-bs-toggle='dropdown'>
                <span>
                    { displayValues.length === 0 ? 'None' : displayValues.join(', ') }
                </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end w-100 bg-light">
                <ul className='list-unstyled row mb-0'>
                    {
                        options.map(x => <li key={x.value} className={ columns === 3 ? 'col-md-4' : columns === 2 ? 'col-md-6' : 'col-12' }>
                            <button className='dropdown-item non-button'
                                type='button'
                                onClick={e => {
                                    onChange(values.includes(x.value)
                                    ? values.filter(y => y !== x.value)
                                    : [...values, x.value])
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                disabled={x.isDisabled}>
                                <i className={`me-1 fa fa-fw ${values.includes(x.value) ? 'fa-check-square' : 'fa-regular fa-square'}`}></i>
                                {x.label}
                            </button>
                        </li>)
                    }
                </ul>
            </div>
        </div>
    </div>;
};