import { useEffect, useState } from 'react';
import { Asset, AssetNote, AssetNoteResponseAction, AssetType, AssetVersion } from '../../../models';
import { addAssetNote, addAssetNoteResponse, deleteAssetNote, deleteAssetNoteResponse, getProjectAssets, saveAssetStatusChange } from '../../../api';
import { Card, HamburgerMenu, MenuItem, MessageBox, Modal, UserMessage } from '../../common';
import { TextEntryElement } from '../../shared';
import { ProjectTabProps } from '../projectViewer';
import { areEqual, ellipsis, printRelativeDate } from '../../../util';
import { useAppContext, useDialog } from '../../../hooks';
import pluralize from 'pluralize';

export interface EnabledAssetActions {
    approve?: boolean;
    requestChanges?: boolean;
    sendMessage?: boolean;
    scheduleMeeting?: boolean;
    notes?: boolean;
};

export interface AssetTabProps extends ProjectTabProps {
    assetType: AssetType;
    enabledActions: EnabledAssetActions;
    facilitatorTitle: string;
    newAssetMessage?: UserMessage;
    promptForNotesOnApproval?: boolean;
}

export const AssetTab = ({ project, setBadge, assetType, enabledActions, facilitatorTitle, newAssetMessage, promptForNotesOnApproval }: AssetTabProps) => {
    const [assets, setAssets] = useState<Asset[] | undefined>(undefined);
    const [assetInView, setAssetInView] = useState<Asset | undefined>(undefined);
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);

    const [ filteredActions, setFilteredActions ] = useState<EnabledAssetActions>({});

    const { alert, prompt, confirm } = useDialog();

    useEffect(() => {
        getProjectAssets(project.id, assetType).then(setAssets);
    }, [project]);

    useEffect(() => {
        if (assets) {
            const newAssets = assets.filter(x => x.versions.some(v => v.status === 'New'));
            setBadge?.(newAssets.length);
        }
    }, [assets]);

    useEffect(() => {
        if (project.status === "In Progress") {
            setFilteredActions(enabledActions);
        } else {
            setFilteredActions({});
        }
    }, [project.status, enabledActions]);

    const onApproval = async (asset: Asset) => {
        if (await confirm(`Are you sure you are ready to approve this ${assetType.toLowerCase()}?`)) {
            const notes = (promptForNotesOnApproval && await prompt({ message: `Enter any notes for the ${facilitatorTitle} (optional):`, multiline: true })) || undefined;
            setIsNonBlockingProcess(true);
            saveAssetStatusChange(project.id, asset.versions[0].id, { type: 'approval', notes }).then(change => {
                if (change) {
                    const _asset = { ...asset };
                    const _version = _asset.versions.find(x => x.id === asset.versions[0].id)!;
                    _version.status = 'Approved';
                    setAssets([...assets!.filter(x => x.label !== asset.label), _asset]);
                    setAssetInView(undefined);
                }
                setIsNonBlockingProcess(false);
            });
        }
    };

    const onChangeRequest = async (asset: Asset) => {
        if (await confirm('Are you sure you are ready to request changes?')) {
            const notes = await prompt({ message: `Enter any notes for the ${facilitatorTitle} (optional):`, multiline: true }) || undefined;
            setIsNonBlockingProcess(true);
            saveAssetStatusChange(project.id, asset.versions[0].id, { type: 'changeRequest', notes }).then(change => {
                if (change) {
                    const _asset = { ...asset };
                    const _version = _asset.versions.find(x => x.id === asset.versions[0].id)!;
                    _version.status = 'Changes Requested';
                    setAssets([...assets!.filter(x => x.label !== asset.label), _asset]);
                    setAssetInView(undefined);
                }
                setIsNonBlockingProcess(false);
            });
        }
    };

    const onSendMessage = async (asset: Asset) => {
        alert('Feature not implemented');
    };

    const onScheduleMeeting = async (asset: Asset) => {
        alert('Feature not implemented');
    };

    const getDefaultNewAssetMessage = () => {
        const title = `Good news! We have new ${pluralize(assetType.toLowerCase())} for your review!`;
        let text = `Click on a ${assetType.toLowerCase()} below to review.`;

        if (filteredActions.notes) {
            text += ` You can use the notes feature there to provide notes for your ${facilitatorTitle.toLowerCase()}.`;
            text += ` Please reach out with any questions.`;
        } else {
            text += ` Please reach out to your ${facilitatorTitle.toLowerCase()} with any questions.`;
        }

        if (filteredActions.scheduleMeeting) {
            text += ` There is also an option to schedule a meeting.`;
        }

        if (filteredActions.approve && filteredActions.requestChanges) {
            text += ` Once you have finished reviewing a ${assetType.toLowerCase()}, please click 'Approve' or use the option to request changes.`;
        } else if (filteredActions.approve && !filteredActions.requestChanges) {
            text += ` Once you have finished reviewing a ${assetType.toLowerCase()}, please click 'Approve'.`;
        } else if (!filteredActions.approve && filteredActions.requestChanges) {
            text += ` Once you have finished reviewing a ${assetType.toLowerCase()}, you may request changes if needed.`;
        }

        return { title, text };
    };

    return <div className={isNonBlockingProcess ? 'wait' : ''}>
        {
            assetInView && <AssetViewer
                projectId={project.id}
                asset={assetInView}
                filteredActions={filteredActions}
                onAssetUpdated={a => setAssets([...assets!.filter(x => x.id !== a.id), a])}
                onAssetApproval={onApproval}
                onAssetChangeRequest={onChangeRequest}
                onSendMessage={onSendMessage}
                onScheduleMeeting={onScheduleMeeting}
                onEscape={() => setAssetInView(undefined)} />
        }
        {
            assets?.some(x => x.versions.some(v => v.status === 'New')) &&
            <MessageBox message={newAssetMessage || getDefaultNewAssetMessage()} />
        }

        <div className='row'>
            {assets?.map((asset, i) =>
                <div key={i} className='col-xxl-4 col-md-6'>
                    <AssetCard
                        asset={asset}
                        filteredActions={filteredActions}
                        setAssetInView={setAssetInView}
                        onAssetApproval={onApproval}
                        onAssetChangeRequest={onChangeRequest}
                        onSendMessage={onSendMessage}
                        onScheduleMeeting={onScheduleMeeting} />
                </div>)}
        </div>
    </div>;
};

interface AssetCardProps {
    asset: Asset;
    filteredActions: EnabledAssetActions;
    setAssetInView: (asset: Asset) => void;
    onAssetApproval: (asset: Asset) => void;
    onAssetChangeRequest: (asset: Asset) => void;
    onSendMessage: (asset: Asset) => void;
    onScheduleMeeting: (asset: Asset) => void;
}

const UserInfo = (version: AssetVersion) => {
    if (!version.lastUpdatedBy) return <></>;
    return <div>
        <div className='float-start'>
            <img src={version.lastUpdatedBy.avatarUrl}
                className='avatar'
                width="50px"
                alt={version.lastUpdatedBy.name} />
        </div>
        <a href={"mailto:" + version.lastUpdatedBy.email}>
            {version.lastUpdatedBy.name}
        </a><br />
        {printRelativeDate(version.lastUpdatedOn)}
        <div className='clearfix'></div>
    </div>;
};

const AssetCard = (props: AssetCardProps) => {
    const currentVersion = props.asset.versions[0];

    const getMenuItems = ({ asset, filteredActions, setAssetInView, onAssetApproval, onAssetChangeRequest, onSendMessage, onScheduleMeeting }: AssetCardProps) => {
        const currentVersion = asset.versions[0];
    
        const menuItems: MenuItem[] = [
            { label: 'Review Online', iconClass: 'fa fa-eye', onClick: () => setAssetInView(asset) },
            { label: 'Download', iconClass: 'fa fa-download', onClick: () => window.open(currentVersion.url, '_blank') }
        ];
    
        if (currentVersion.status !== 'Approved') {
            const countBefore = menuItems.length;
    
            if (filteredActions.sendMessage) {
                menuItems.push({ label: 'Send Message', iconClass: 'fa fa-envelope', onClick: () => onSendMessage(asset) });
            }
    
            if (filteredActions.scheduleMeeting) {
                menuItems.push({ label: 'Schedule Meeting', iconClass: 'fa fa-calendar', onClick: () => onScheduleMeeting(asset) });
            }
    
            if (filteredActions.requestChanges && currentVersion.status !== 'Changes Requested') {
                menuItems.push({ label: 'Request Changes', iconClass: 'fa fa-pencil', onClick: () => onAssetChangeRequest(asset) });
            }
    
            if (filteredActions.approve) {
                menuItems.push({ label: `Approve ${asset.type}`, iconClass: 'fa fa-user-check', onClick: () => onAssetApproval(asset) });
            }
    
            if (menuItems.length > countBefore) {
                menuItems.splice(countBefore, 0, { type: 'divider' });
            }
        }
    
        return menuItems;
    };

    const menuItems = getMenuItems(props);

    return <Card title={props.asset.label} iconClass='fa fa-regular fa-file'>
        <img className='card-img mb-2 clickable' src={currentVersion.thumbnailUrl} alt={currentVersion.fileName} onClick={() => props.setAssetInView(props.asset)} />
        <div className="d-flex flex-row">
            <div className="flex-grow-1 text-truncate">
                <button className='btn btn-link p-0' onClick={() => props.setAssetInView(props.asset)}>
                    {ellipsis(currentVersion.fileName, 50)}
                </button>
                <div className='text-muted mb-4'>
                    Version: {currentVersion.version}
                </div>
                <UserInfo {...currentVersion} />
            </div>
            <div>
                <span className='badge text-bg-dark'>
                    {currentVersion.status}
                </span>
                {(props.filteredActions.notes || !!currentVersion.notes?.length) &&
                    <div className='text-end mt-5'>
                        <i className='fa-regular fa-message'></i>&nbsp;{ currentVersion.notes?.length || 0 }
                    </div>}
            </div>
        </div>
        <div className='card-footer text-center' style={{ margin: "1rem -1rem -1rem" }}>
            <HamburgerMenu type='h-ellipsis' items={menuItems} direction='up' />
        </div>

    </Card>;
};

interface AssetViewerProps {
    projectId: string;
    asset: Asset;
    filteredActions: EnabledAssetActions;
    onAssetUpdated: (asset: Asset) => void;
    onAssetApproval: (asset: Asset) => void;
    onAssetChangeRequest: (asset: Asset) => void;
    onSendMessage: (asset: Asset) => void;
    onScheduleMeeting: (asset: Asset) => void;
    onEscape: () => void;
}

const AssetViewer = ({ projectId, asset, filteredActions, onAssetUpdated, onAssetApproval, onAssetChangeRequest, onSendMessage, onScheduleMeeting, onEscape }: AssetViewerProps) => {
    const [assetVersion, setAssetVersion] = useState<AssetVersion>(asset.versions[0]);
    const isCurrentVersion = assetVersion.version === asset.versions.length;
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);
    const [iframeMinHeight, setIframeMinHeight] = useState<number>(700);

    const { user } = useAppContext();

    const onNotesUpdated = (notes: AssetNote[]) => {
        const _asset = { ...asset };
        const _version = _asset.versions.find(x => x.id === assetVersion.id)!;
        _version.notes = notes;
        onAssetUpdated?.(_asset);
        setIsNonBlockingProcess(false);
    };

    useEffect(() => {
        const handleResize = () => {
            const modal = document.querySelector('.modal.show .modal-content');
            const iframe = document.querySelector('iframe.asset-viewer');
            if (modal && iframe) {
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                const modalMarginY = modal.getBoundingClientRect().top;
                const iframeDistanceFromTop = iframe.getBoundingClientRect().top;
                // doubling modal's margin Y safely covers the padding of modal body
                const minHeight = viewportHeight - (iframeDistanceFromTop + (modalMarginY * 2));
                setIframeMinHeight(minHeight);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);

            if (assetVersion.status === 'New' && !user!.roles.includes('admin')) {
                saveAssetStatusChange(projectId, assetVersion.id, { type: 'review' }).then(change => {
                    if (change) {
                        const _asset = { ...asset };
                        const _version = _asset.versions.find(x => x.id === assetVersion.id)!;
                        _version.status = 'In Review';
                        setTimeout(() => onAssetUpdated?.(_asset), 2000); // wait 2 seconds to reducing jaringness of UI change
                    }
                });
            }
        };
    }, [assetVersion]);

    return <Modal title={asset.label} size='xxl' onEscape={onEscape}>
        <div className={"row" + (isNonBlockingProcess ? ' wait' : '')}>
            {
                asset.versions.length > 1 && <div className="col-12">
                    <ul className="nav nav-pills mb-4">
                        {
                            asset.versions.map((version, i) => <li key={i} className='nav-item'>
                                <button type='button'
                                    className={`nav-link pb-0 ${assetVersion.id === version.id ? 'active' : ''}`}
                                    onClick={() => setAssetVersion(version)}>
                                    <img src={version.thumbnailUrl} style={{ maxWidth: "75px", maxHeight: "75px" }} alt={version.fileName} />
                                    <div className='text-center text-lowercase'>
                                        v{version.version}
                                    </div>
                                </button>
                            </li>)
                        }
                    </ul>
                </div>
            }
            <div className="col-md-9 sm-mb-3">
                {
                    !assetVersion.mimeType.startsWith('image/') && <iframe src={assetVersion.url} className="asset-viewer" style={{ width: '100%', height: '100%', minHeight: iframeMinHeight + "px" }}></iframe>
                }
                {
                    assetVersion.mimeType.startsWith('image/') && <img src={assetVersion.url} width="100%" className='img-fluid' alt={assetVersion.fileName} />
                }
            </div>
            <div className='col-md-3'>
                {
                    ((filteredActions.notes && assetVersion.allowNotes) || !!assetVersion.notes?.length) && <AssetNotesViewer
                        projectId={projectId}
                        assetVersionId={assetVersion.id}
                        title={`Version ${assetVersion.version} Feedback`}
                        notes={assetVersion.notes || []}
                        isClosed={!isCurrentVersion || assetVersion.status === 'Approved'}
                        onNotesUpdating={() => setIsNonBlockingProcess(true)}
                        onNotesUpdated={onNotesUpdated} />
                }
                {
                    isCurrentVersion && assetVersion.status !== 'Approved' && <>
                        {
                            filteredActions.sendMessage &&
                            <button type='button' className='btn btn-secondary w-100 mb-3' onClick={() => onSendMessage(asset)}>
                                Send Message
                            </button>
                        }
                        {
                            filteredActions.scheduleMeeting &&
                            <button type='button' className='btn btn-secondary w-100 mb-3' onClick={() => onScheduleMeeting(asset)}>
                                Schedule Meeting
                            </button>
                        }
                        {
                            filteredActions.requestChanges && assetVersion.status !== 'Changes Requested' &&
                            <button type='button' className='btn btn-secondary w-100 mb-3' onClick={() => onAssetChangeRequest(asset)}>
                                Request Changes
                            </button>
                        }
                        {
                            filteredActions.approve &&
                            <button type='button' className='btn btn-primary w-100 mb-3' onClick={() => onAssetApproval(asset)}>
                                Approve {asset.type}
                            </button>
                        }
                        {
                            areEqual(filteredActions, {}) &&
                            <div className='text-center text-muted'>
                                No actions are available.
                            </div>
                        }
                    </>
                }
                {
                    !isCurrentVersion &&
                    <div className='text-center text-muted'>
                        You are viewing an outdated version.
                    </div>
                }
                {
                    isCurrentVersion && assetVersion.status === 'Approved' &&
                    <div className='text-center text-muted'>
                        <i className='fa fa-circle-check me-2'></i>
                        This {asset.type.toLowerCase()} has been approved.
                    </div>
                }
                {
                    isCurrentVersion && assetVersion.status === 'Changes Requested' &&
                    <div className='text-center text-muted'>
                        <i className='fa-regular fa-clock me-2'></i>
                        Changes have been requested.
                    </div>
                }
            </div>
        </div>
    </Modal>;
};

interface AssetNotesViewerProps {
    title: string;
    notes: AssetNote[];
    projectId: string;
    assetVersionId: string;
    isClosed?: boolean;
    onNotesUpdating: () => void;
    onNotesUpdated: (notes: AssetNote[]) => void;
}

const AssetNotesViewer = ({ title, notes, projectId, assetVersionId, isClosed, onNotesUpdating, onNotesUpdated }: AssetNotesViewerProps) => {
    const [newNoteText, setNewNoteText] = useState<string>("");

    const onNoteAdded = (text: string) => {
        onNotesUpdating();
        addAssetNote(projectId, assetVersionId, { text }).then(note => {
            if (note) {
                onNotesUpdated?.([...notes, note]);
                setNewNoteText('');
            } else {
                onNotesUpdated?.([...notes]);
            }
        });
    };

    const onNoteDeleted = (noteId: string) => {
        onNotesUpdating();
        deleteAssetNote(projectId, assetVersionId, noteId).then(() => {
            onNotesUpdated?.([...notes].filter(x => x.id !== noteId));
        });
    }

    const onNoteResponse = (noteId: string, text?: string, action?: AssetNoteResponseAction) => {
        onNotesUpdating();
        addAssetNoteResponse(projectId, assetVersionId, noteId, { text, action }).then(response => {
            if (response) {
                const _notes = [...notes];
                const _note = _notes.find(x => x.id === noteId)!;
                _note.responses = [...(_note.responses || []), response];
                if (action === 'resolve') {
                    _note.isResolved = true;
                } else if (action === 'reopen') {
                    _note.isResolved = false;
                }
                onNotesUpdated?.(_notes);
                setNewNoteText('');
            } else {
                onNotesUpdated?.([...notes]);
            }
        });
    };

    const onNoteResponseDeleted = (noteId: string, responseId: string) => {
        onNotesUpdating();
        deleteAssetNoteResponse(projectId, assetVersionId, noteId, responseId).then(() => {
            const _notes = [...notes];
            const _note = _notes.find(x => x.id === noteId)!;
            _note.responses = _note.responses!.filter(x => x.id !== responseId);
            onNotesUpdated?.(_notes);
        });
    }

    return <>
        <Card title={title} iconClass='fa fa-regular fa-message'>
            <div className='notes'>
                {
                    !notes?.length && <div className='text-muted text-center'>
                        No notes to display.
                    </div>
                }
                {
                    notes?.map((note, i) =>
                        <AssetNoteCard key={i}
                            note={note}
                            onNoteDeleted={onNoteDeleted}
                            onNoteResponse={onNoteResponse}
                            onNoteResponseDeleted={onNoteResponseDeleted}
                            isClosed={isClosed} />)
                }
            </div>
            {
                !isClosed && <>
                    <hr />
                    <div>
                        <TextEntryElement
                            value={newNoteText}
                            onValueChange={setNewNoteText}
                            config={{ prompt: "", multiline: true }} />
                        <div>
                            <button type="button"
                                className='btn btn-outline-primary btn-sm w-100'
                                onClick={() => {
                                    onNoteAdded(newNoteText);
                                    setNewNoteText('');
                                }}>
                                Add Note
                            </button>
                        </div>
                    </div>
                </>
            }
        </Card>
    </>;
}

interface AssetNoteCardProps {
    note: AssetNote;
    onNoteDeleted: (noteId: string) => void;
    onNoteResponse: (noteId: string, text?: string, action?: AssetNoteResponseAction) => void;
    onNoteResponseDeleted: (noteId: string, responseId: string) => void;
    isClosed?: boolean;
}

const AssetNoteCard = ({ note, onNoteDeleted, onNoteResponse, onNoteResponseDeleted, isClosed }: AssetNoteCardProps) => {
    const [menuItems, setMenuItems] = useState<MenuItem[] | undefined>();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(note.isResolved);

    const { user } = useAppContext();
    const { alert, prompt, confirm } = useDialog();

    useEffect(() => {
        if (!isClosed) {
            const _menuItems: MenuItem[] = [];

            if (!note.isResolved) {
                _menuItems.push({
                    label: 'Reply', iconClass: 'fa fa-reply', onClick: async () => {
                        const text = await prompt({ message: 'Enter your response:', multiline: true });
                        if (text) {
                            onNoteResponse(note.id, text);
                        } else {
                            alert('You must enter a response.');
                        }
                    }
                });
                _menuItems.push({
                    label: 'Mark Resolved',
                    iconClass: 'fa fa-circle-check',
                    onClick: () => onNoteResponse(note.id, undefined, 'resolve')
                });
            } else {
                _menuItems.push({
                    label: 'Reopen',
                    iconClass: 'fa fa-rotate-left',
                    onClick: async () => {
                        const text = await prompt({ message: 'Comment (optional)', multiline: true });
                        onNoteResponse(note.id, !!text ? text : undefined, 'reopen')
                    }
                });
            }

            if (note.author?.id === user!.id) {
                _menuItems.push({ type: 'divider' });
                _menuItems.push({
                    label: 'Delete Note',
                    iconClass: 'fa fa-trash',
                    onClick: async () => {
                        if (await confirm('Are you sure you want to delete this note?')) {
                            onNoteDeleted(note.id);
                        }
                    }
                });
            }

            setMenuItems(_menuItems);
        } else {
            setMenuItems(undefined);
        }

    }, [note, note.isResolved, isClosed]);

    useEffect(() => {
        setIsCollapsed(note.isResolved);
    }, [note.isResolved]);

    return <div className={'note' + (note.isResolved ? ' resolved' : '')}>
        {
            menuItems?.length &&
            <div className='float-end'>
                <HamburgerMenu type='v-ellipsis' items={menuItems} size='xs' />
            </div>
        }
        <div className='author'>
            <div>
                {
                    note.author?.avatarUrl && <img src={note.author.avatarUrl}
                        className='avatar float-start'
                        width="30px"
                        alt={note.author.name} />
                }
                <strong>{ellipsis(note.author?.name || note.author?.email || "Unknown User", 30)}</strong><br />
                {printRelativeDate(note.date)}
            </div>
        </div>
        <div className='text clearfix'>
            {note.text}
        </div>
        {
            note.isResolved && (note.responses?.length || 0) > 1 &&
            <div>
                <hr />
                <div className='text-center'>
                    <button type='button' className='btn btn-link btn-sm' onClick={() => setIsCollapsed(!isCollapsed)}>
                        {
                            isCollapsed && <>Show {note.responses!.length - 1} more response{note.responses!.length > 2 ? 's' : ''}</>
                        }
                        {
                            !isCollapsed && <>Collapse Thread</>
                        }
                    </button>
                </div>
            </div>
        }
        {
            note.responses?.filter((x, i) => !isCollapsed || i === (note.responses!.length - 1)).map((response, i) =>
                <div key={i}>
                    <hr />
                    {
                        (!isClosed && response.author?.id === user!.id && !response.action) && <div className='float-end'>
                            <HamburgerMenu type='v-ellipsis' items={[
                                {
                                    label: 'Delete Response',
                                    iconClass: 'fa fa-trash',
                                    onClick: async () => {
                                        if (await confirm('Are you sure you want to delete this response?')) {
                                            onNoteResponseDeleted(note.id, response.id);
                                        }
                                    }
                                }
                            ]} size='xs' />
                        </div>
                    }
                    <div className='author'>
                        <div>
                            {
                                response.author?.avatarUrl && <img src={response.author.avatarUrl}
                                    className='avatar float-start'
                                    width="30px"
                                    alt={response.author.name} />
                            }
                            <strong>{ellipsis(response.author?.name || response.author?.email || "Unknown User", 30)}</strong><br />
                            {printRelativeDate(response.date)}
                        </div>
                    </div>
                    <div className='text clearfix'>
                        {
                            response.action &&
                            <div className='text-gray'>
                                <i className={'me-1 fa-fw' + (response.action === 'resolve' ? ' fa fa-circle-check' : ' fa fa-rotate-left')}></i>
                                {response.action === 'resolve' ? 'Resolved Note' : 'Reopened Note'}
                            </div>
                        }
                        {
                            response.text &&
                            <div className={response.action ? 'mt-1' : ''}>
                                {response.text}
                            </div>
                        }
                    </div>

                </div>)
        }
    </div>;
}