import { DragEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { listQuestionSets, reorderQuestionSets } from '../../../api';
import { QuestionSet } from '../../../models';
import { printRelativeDate } from '../../../util';
import { Card, Modal } from '../../common';
import './questionSetList.scss';

export const QuestionSetList = () => {
  const [questionSets, setQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
  const [showSorter, setShowSorter] = useState(false);

  const saveSorting = (questionSets: QuestionSet[]) => {
    reorderQuestionSets(questionSets.map(x => x.id)).then(x => {
      setQuestionSets(x);
      setShowSorter(false);
    });
  };

  useEffect(() => {
    listQuestionSets().then(setQuestionSets);
  }, []);

  if (questionSets === undefined) {
    return <div>Loading Question Sets...</div>;
  }

  return <div className="row">
    <div className="col-12">
      <div className='float-end justify-content-end'>
        <button type="button"
          onClick={() => setShowSorter(true)}
          className='btn btn-outline-secondary ms-2'>
          Reorder
        </button>
        <Link className='btn btn-primary ms-2' to="/admin/question-sets/new">
          New Question Set
        </Link>
      </div>
      <h1>
        Question Sets
      </h1>
      <div className='clearfix'></div>
    </div>
    <div className='mb-4'>
      <ul className="nav nav-underline">
        <li className="nav-item">
          <a className="nav-link active" href="#">
            Design Specifications
          </a>
        </li>
      </ul>
    </div>
    {
      questionSets.length === 0 &&
      <div className='col-12 text-center'>
        <div className='box'>
          There are no question sets yet. Click the "New Question Set" button to get started.
        </div>
      </div>
    }
    {questionSets.map(QuestionSetCard)}
    {showSorter && <QuestionSetSorter questionSets={questionSets} onSave={saveSorting} onCancel={() => setShowSorter(false)} />}
  </div>;
}

const QuestionSetCard = (questionSet: QuestionSet) =>
  <div key={questionSet.key} className='col-md-3'>
    <Card title={questionSet.name} iconClass='fa fa-layer-group'>
      <div className='row'>
        <div className='col-md-8 text-sm text-muted fs-6'>
          Fields: {questionSet.elements?.filter(x => x.config && (x.config! as any)['prompt']).length}<br />
          Updated: {printRelativeDate(questionSet.lastUpdatedOn)}
        </div>
        <div className='col-md-4 text-end'>
          <Link className='btn btn-sm btn-outline-primary' to={`/admin/question-sets/${questionSet.key}`}>
            <i className='fa fa-pencil fa-fw'></i>
          </Link>
        </div>
      </div>
    </Card>
  </div>;

interface QuestionSetSorterProps {
  questionSets: QuestionSet[];
  onSave: (questionSets: QuestionSet[]) => void;
  onCancel: () => void;
}

const QuestionSetSorter = ({ questionSets, onSave, onCancel }: QuestionSetSorterProps) => {
  const [sortedQuestionSets, setSortedQuestionSets] = useState<QuestionSet[]>(questionSets);
  const [draggedIndex, setDraggedIndex] = useState<number | undefined>(undefined);
  const [activeDropzone, setActiveDropzone] = useState<number | undefined>(undefined);

  const onDragEnter = (index: number) => {
    setActiveDropzone(index);
  };

  const onDragStart = (e: DragEvent<HTMLElement>, index: number) => {
    e.dataTransfer.setData('index', index.toString());
    e.dataTransfer.setDragImage(e.currentTarget, 1, 1);
    setDraggedIndex(index);
    setActiveDropzone(index);
  };

  const onDragEnd = () => {
    setDraggedIndex(undefined);
    setActiveDropzone(undefined);
  };

  const onDrop = (e: DragEvent<HTMLElement>, newIndex: number) => {
    e.preventDefault();
    const oldIndex = parseInt(e.dataTransfer.getData('index'), 10);
    const _sortedQuestionSets = [...sortedQuestionSets];
    const [movedItem] = _sortedQuestionSets.splice(oldIndex, 1);
    _sortedQuestionSets.splice(newIndex, 0, movedItem);
    setSortedQuestionSets(_sortedQuestionSets);
    setDraggedIndex(undefined);
    setActiveDropzone(undefined);
  };

  return <Modal title="Reorder Question Sets"
    onConfirm={() => onSave(sortedQuestionSets)}
    onCancel={() => onCancel()}>
    <div>
      <p>
        <i className='fa fa-up-down-left-right me-1'></i> Drag and drop the question sets to reorder them.
      </p>
      <div>
        <ul className='list-group'>
          {sortedQuestionSets.map((questionSet, i) =>
            <li
              key={questionSet.key}
              className={`list-group-item draggable ${draggedIndex === i ? 'dragging' : ''} ${activeDropzone === i ? 'dropzone' : ''}`}
              draggable
              onDragEnter={() => onDragEnter(i)}
              onDragStart={(e) => onDragStart(e, i)}
              onDragEnd={onDragEnd}
              onDrop={(e) => onDrop(e, i)}
              onDragOver={(e) => e.preventDefault()}>
              <i className='drag-handle fa fa-grip-vertical me-3'></i>
              {questionSet.name}
            </li>
          )}
        </ul>
      </div>
    </div>
  </Modal>;
}