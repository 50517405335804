import pluralize from 'pluralize';
import { useState, useEffect } from 'react';
import { useDialog } from '../../hooks';
import { Project, Asset, MaterialSheet, Material, MaterialArea, MaterialElection } from '../../models';
import './materialSheetViewer.scss';

export interface MaterialSheetViewerProps {
    project: Project;
    designs: Asset[];
    materialSheet: MaterialSheet;
    materials: Material[];
    editMode?: boolean;
    setEditingArea?: (area: MaterialArea) => void;
    setEditingMaterial?: (material: Material) => void;
    setEditingElection?: (election: MaterialElection) => void;
    updateMaterialSheet?: (materialSheet: MaterialSheet) => void;
}

export const MaterialSheetViewer = ({ project, designs, materialSheet, materials, editMode, setEditingArea, setEditingElection, setEditingMaterial, updateMaterialSheet }: MaterialSheetViewerProps) => {
    const [showQuantity, setShowQuantity] = useState<boolean>(false);
    const [showNotes, setShowNotes] = useState<boolean>(false);

    const { confirm } = useDialog();

    useEffect(() => {
        if (!materialSheet || !materials) return;

        const hasUnitOfMeasure = materialSheet.areas?.some(area =>
            area.elections.some(election => {
                const material = materials.find(m => m.id === election.materialId);
                return material?.unitOfMeasure;
            })
        ) || false;

        setShowQuantity(hasUnitOfMeasure);
    }, [materialSheet, materials]);

    useEffect(() => {
        if (!materialSheet) return;

        const hasNotes = materialSheet.areas?.some(area =>
            area.elections.some(election => !!election.notes)
        ) || false;

        setShowNotes(hasNotes);
    }, [materialSheet]);

    return <>
        <div className='logo text-end fa-2x float-end'>
            BLOUNT | DESIGNS
        </div>
        <div>
            <h2>Materials</h2>
            {project.address.street} | <strong>{project.ownerName}</strong>
                {
                    materialSheet.designAssetId &&
                    <div>
                        Design: {designs.find(d => d.id === materialSheet.designAssetId)?.versions[0].label}
                    </div>
                }
        </div>
        <div className="clear-fix"></div>
        <hr />
        {!materialSheet.areas?.length &&
            <div className="text-muted text-center mt-4">
                To get started, add an area to the material sheet.
            </div>
        }
        {materialSheet.areas?.map((area, i) => <div key={i} className="mb-4">
            <h4 className="area-title">
                {area.title}
                {
                    editMode &&
                    <div className='area-toolbar'>
                        <div className="btn-group btn-group-sm" role="group">
                            <button type="button"
                                className="btn btn-outline-secondary drag-handle">
                                <i className='fa fa-fw fa-up-down-left-right'></i>
                            </button>
                            <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={async () => setEditingArea!(area)}>
                                <i className='fa fa-fw fa-pencil'></i>
                            </button>
                            <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={async () => {
                                    const confirmed = await confirm(`Are you sure you want to remove ${area.title}?`);
                                    if (confirmed) {
                                        materialSheet.areas = materialSheet.areas!.filter(a => a.title !== area.title);
                                        updateMaterialSheet!({ ...materialSheet });
                                    }
                                }}>
                                <i className='fa fa-fw fa-trash'></i>
                            </button>
                        </div>
                    </div>
                }
            </h4>
            {
                area.description &&
                <p
                    dangerouslySetInnerHTML={{
                        __html: area.description?.replace(/\r?\n|\r/g, '<br/>'),
                    }}
                />
            }
            {
                !area.elections.length &&
                <div className="text-muted text-center box">
                    No materials have been added to this area.
                </div>
            }
            {
                area.elections.length > 0 &&
                <div className="table-responsive">
                    <table className="table table-bordered table-striped table-table-borderless nowrap-headers nowrap-columns">
                        <thead>
                            <tr>
                                <th>Material</th>
                                <th>Specifications</th>
                                {showQuantity && <th>Quantity</th>}
                                {showNotes && <th>Notes</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {area.elections?.map((election, j) => {
                                const material = materials.find(m => m.id === election.materialId);
                                if (!material) return <></>;
                                return <tr key={j} className="material-wrapper">
                                    <td>
                                        {
                                            editMode &&
                                            <div className='material-toolbar'>
                                                <div className="btn-group btn-group-sm" role="group">
                                                    <button type="button"
                                                        className="btn btn-outline-secondary drag-handle">
                                                        <i className='fa fa-fw fa-up-down-left-right'></i>
                                                    </button>
                                                    <button type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={() => {
                                                            setEditingMaterial!(material);
                                                            setEditingElection!(election);
                                                        }}>
                                                        <i className='fa fa-fw fa-pencil'></i>
                                                    </button>
                                                    <button type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={async () => {
                                                            const confirmed = await confirm(`Are you sure you want to remove ${material.name} from this area?`);
                                                            if (confirmed) {
                                                                area.elections = area.elections.filter(e => e.id !== election.id);
                                                                updateMaterialSheet!({ ...materialSheet });
                                                            }
                                                        }}>
                                                        <i className='fa fa-fw fa-trash'></i>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {material.name}
                                        {election.label && <div className="text-muted">({election.label})</div>}
                                    </td>
                                    <td>
                                        <MaterialElectionViewer material={material} election={election} />
                                    </td>
                                    {
                                        showQuantity &&
                                        <td>
                                            {
                                                election.quantity &&
                                                <>
                                                    {election.quantity} {material.unitOfMeasure && pluralize(material.unitOfMeasure, +(election.quantity))}
                                                </>
                                            }
                                        </td>
                                    }
                                    {
                                        showNotes &&
                                        <td>
                                            {election.notes || "N/A"}
                                        </td>
                                    }
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>)}
    </>
};

interface MaterialElectionViewerProps {
    election: MaterialElection;
    material: Material;
}

const MaterialElectionViewer = ({ election, material }: MaterialElectionViewerProps) => {
    if (election.specifications?.length === 0) {
        return <div className="text-muted">N/A</div>;
    }

    return <div>
        <div className="float-end ms-2">
            {
                material.properties.map((property, k) => {
                    const spec = election.specifications.find(s => s.propertyId === property.id);
                    if (!spec || (!spec.optionId && !spec.value)) return <></>;
                    const option = property.options.find(o => o.id === spec?.optionId);
                    return option?.imageUrl ?
                        <div className="mb-2" key={k}>
                            <img src={option.imageUrl} alt={option.name} style={{ maxWidth: '75px', maxHeight: '75px' }} />
                            <div className="text-xs text-uppercase">{property.name}</div>
                        </div> :
                        <></>;
                })
            }
        </div>
        {
            material.properties.map((property, k) => {
                const spec = election.specifications.find(s => s.propertyId === property.id);
                if (!spec || (!spec.optionId && !spec.value)) return <></>;
                const option = property.options.find(o => o.id === spec?.optionId);
                return (
                    <div key={k}>
                        <strong className="text-sm text-uppercase">{property.name}</strong>: {option ? option.name : spec?.value}
                        {option?.description && <div className="text-muted">{option.description}</div>}
                        {option?.manufacturer && <div>({option.manufacturer})</div>}
                    </div>
                );
            })
        }
        <div className="clearfix"></div>
    </div>
};
