import { createElement, useEffect, useState } from "react";
import {
    ElementType,
    ElementConfig,
    HeaderElementConfig,
    TextElementConfig,
    ImageSetElementConfig,
    TextEntryElementConfig,
    RadioButtonsElementConfig,
    DropdownElementConfig,
    CheckboxesElementConfig,
    ToggleSwitchElementConfig,
    ImageSelectorElementConfig,
    FileUploadElementConfig
} from "../../../models";
import { generateUUID } from "../../../util";
import { FileValue, FormOption, ImageCarousel } from "../../common";

import "./elementViewer.scss";

interface ElementProps<T extends ElementConfig> {
    config: T;
    value?: any;
    onValueChange?: (value: any) => void;
    isDisabled?: boolean;
}

export const ElementViewer = ({ elementType, config, value, onValueChange, isDisabled }: { elementType: ElementType } & ElementProps<ElementConfig>) => {
    const elementMap: Record<ElementType, any> = {
        'Header': HeaderElement,
        'Text': TextElement,
        'ImageSet': ImageSetElement,
        'Divider': DividerElement,
        'TextEntry': TextEntryElement,
        'RadioButtons': RadioButtonsElement,
        'Dropdown': DropdownElement,
        'Checkboxes': CheckboxesElement,
        'ToggleSwitch': ToggleSwitchElement,
        'ImageSelector': ImageSelectorElement,
        'FileUpload': FileUploadElement,
    };

    const Element = elementMap[elementType];
    return <Element config={config as any} value={value} onValueChange={onValueChange} isDisabled={isDisabled} />;
};

// --- Implementations --- //

export const HeaderElement = ({ config }: ElementProps<HeaderElementConfig>) => {
    const HeaderTag = `h${config.level}` as keyof JSX.IntrinsicElements;
    return createElement(HeaderTag, null, config.text);
};

export const TextElement = ({ config }: ElementProps<TextElementConfig>) => {
    const paragraphs = config.text?.split(/\n\s*\n/);
    return <div>
        {paragraphs.map((p, i) => (
            <p className="text-muted" key={i}>{p}</p>
        ))}
    </div>;
};

export const ImageSetElement = ({ config }
    : ElementProps<ImageSetElementConfig>) => <div style={{ maxWidth: '100%' }}>
        <ImageCarousel images={config.images} />
    </div>;

export const DividerElement = () => <hr />;

export const TextEntryElement = ({ config, value, onValueChange, isDisabled }: ElementProps<TextEntryElementConfig>) => {
    const [currentValue, setCurrentValue] = useState<string>(value || '');

    useEffect(() => setCurrentValue(value || ''), [value]);

    const id = generateUUID();
    return <div className="form-group">
        <label htmlFor={id}>{config.prompt}</label>
        {!config.multiline &&
            <input type="text"
                className="form-control"
                id={id} name={id}
                value={currentValue}
                disabled={isDisabled}
                onChange={(e) => setCurrentValue?.(e.target.value)}
                onBlurCapture={() => {
                    if (currentValue !== value) {
                        onValueChange?.(currentValue);
                    }
                }} />}
        {config.multiline &&
            <textarea
                className="form-control"
                id={id}
                name={id}
                value={currentValue}
                disabled={isDisabled}
                rows={5}
                onChange={(e) => setCurrentValue?.(e.target.value)}
                onBlurCapture={() => {
                    if (currentValue !== value) {
                        onValueChange?.(currentValue);
                    }
                }} />}
    </div>;
};

export const RadioButtonsElement = ({ config, value, onValueChange, isDisabled }: ElementProps<RadioButtonsElementConfig>) => {
    const id = generateUUID();
    const colClass = `col-md-${Math.round(12 / (config.columns || 1))}`;
    return <div>
        <label htmlFor={id}>{config.prompt}</label>
        <div className="row">
            {
                config.options.map((x, i) => (
                    <div key={i} className={colClass}>
                        {
                            typeof x === 'string' && <>
                                <input type="radio"
                                    id={`${id}-${i}`}
                                    name={id}
                                    disabled={isDisabled}
                                    checked={value === x}
                                    onChange={() => onValueChange?.(x)} />
                                <label htmlFor={`${id}-${i}`}>
                                    {x}
                                </label>
                            </>
                        }
                        {
                            typeof x !== 'string' && <>
                                <input type="radio"
                                    id={`${id}-${i}`}
                                    name={id}
                                    disabled={isDisabled || (x as FormOption).isDisabled}
                                    checked={value === x.value}
                                    onChange={() => onValueChange?.(x.value)} />
                                <label htmlFor={`${id}-${i}`}>
                                    {x.label || x.value}
                                </label>
                            </>
                        }
                    </div>
                ))
            }
        </div>
    </div>;
};

export const DropdownElement = ({ config, value, onValueChange, isDisabled }: ElementProps<DropdownElementConfig>) => {
    const id = generateUUID();
    return <div className="form-group">
        <label htmlFor={id}>{config.prompt}</label>
        <select id={id}
            name={id}
            value={value}
            disabled={isDisabled}
            className="form-control"
            onChange={(e) => onValueChange?.(e.target.value)}>
            {
                config.options.map((x, i) => (
                    <option key={i} value={(x as FormOption).value || x} disabled={(x as FormOption).isDisabled}>
                        {(x as FormOption).label || (x as FormOption).value || x}
                    </option>
                ))
            }
        </select>
    </div>;
}

export const CheckboxesElement = ({ config, value, onValueChange, isDisabled }: ElementProps<CheckboxesElementConfig>) => {
    const id = generateUUID();
    const colClass = `col-md-${Math.round(12 / (config.columns || 1))}`;
    return <div>
        <label htmlFor={id}>{config.prompt}</label>
        <div className="row">
            {
                config.options.map((x, i) => (
                    <div key={i} className={colClass}>
                        {
                            typeof x === 'string' && <>
                                <input type="checkbox"
                                    id={`${id}-${i}`}
                                    name={id}
                                    value={x}
                                    disabled={isDisabled}
                                    checked={value?.includes(x)}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        if (checked) {
                                            onValueChange?.([...value, x]);
                                        } else {
                                            onValueChange?.(value.filter((y: any) => y !== x));
                                        }
                                    }} />
                                <label htmlFor={`${id}-${i}`}>
                                    {x}
                                </label>
                            </>
                        }
                        {
                            typeof x !== 'string' && <>
                                <label htmlFor={`${id}-${i}`}>
                                    <input type="checkbox"
                                        id={`${id}-${i}`}
                                        name={id}
                                        value={x.value}
                                        disabled={isDisabled || x.isDisabled}
                                        checked={value?.includes(x.value)}
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            if (checked) {
                                                onValueChange?.([...value, x.value]);
                                            } else {
                                                onValueChange?.(value.filter((z: any) => z !== x.value));
                                            }
                                        }} />
                                    {x.label || x.value}
                                </label>
                            </>
                        }
                    </div>
                ))
            }
        </div>
    </div>;
};

export const ToggleSwitchElement = ({ config, value, onValueChange, isDisabled }
    : ElementProps<ToggleSwitchElementConfig>) => {
    const id = generateUUID();
    return <div>
        <label htmlFor={id}>
            <input type="checkbox"
                id={id}
                name={id}
                disabled={isDisabled}
                checked={value}
                onChange={(e) => onValueChange?.(e.target.checked)} />
            {config.prompt}
        </label>
    </div>;
};

export const ImageSelectorElement = ({ config, value, onValueChange, isDisabled }
    : ElementProps<ImageSelectorElementConfig>) => {
    const id = generateUUID();
    return <div className="form-group">
        <label htmlFor={id}>{config.prompt}</label>
        <div className="row">
            {
                config.images.map((img, i) => (
                    <div key={i} className="col-md-3 mb-3">
                        <div className="d-flex">
                            <div className="d-flex">
                                {
                                    config.allowMultipleSelection
                                        ? <input type="checkbox"
                                            id={`${id}-${i}`}
                                            name={id}
                                            disabled={isDisabled}
                                            value={img.src}
                                            checked={value === img.src}
                                            onChange={() => onValueChange?.(img.src)} />
                                        : <input type="radio"
                                            id={`${id}-${i}`}
                                            name={id}
                                            disabled={isDisabled}
                                            value={img.src}
                                            checked={value === img.src}
                                            onChange={() => onValueChange?.(img.src)} />
                                }
                            </div>
                            <div>
                                <label htmlFor={`${id}-${i}`}>
                                    <img src={img.src} alt={img.caption} style={{ maxWidth: '150px', maxHeight: '150px' }} /><br />
                                    <span className="caption">{img.caption}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>;
};

export const FileUploadElement = ({ config, value, onValueChange, isDisabled }
    : ElementProps<FileUploadElementConfig>) => {
    const id = generateUUID();

    const uploadFile = (files: FileList | null) => {
        if (files && files.length > 0) {
            const newFiles: FileValue[] = [];

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const file = { name: files[i].name, mimeType: files[i].type, content: event.target?.result as string, size: files[i].size };
                    newFiles.push(file);

                    if (newFiles.length === files.length) {
                        onValueChange?.(newFiles);
                    }
                };

                reader.readAsDataURL(files[i]);
            }
        }
    };

    return <div className="form-group">
        <label htmlFor={id}>
            {config.prompt}
        </label>
        <div className="input-group">
            <button type="button" className="btn btn-secondary" onClick={() => document.getElementById(id)?.click()}>
                Choose File
            </button>
            <span className="form-control">
                {value?.length || 0} file(s) selected
            </span>
        </div>
        <input type="file"
            id={id}
            name={id}
            disabled={isDisabled}
            onChange={(e) => uploadFile(e.target.files)}
            style={{ display: 'none' }}
            multiple={config.allowMultipleSelection}
            accept={config.acceptedFileTypes} />
        {
            value?.length > 0 &&
            <div className="uploads mt-3">
                {
                    value.map((file: FileValue, i: number) => (
                        <div key={i}>
                            <a href={file.content} target="_blank">
                                {
                                    typeof file === "string" && (file as string).startsWith('data:image/') &&
                                        <img src={file} className="upload-image" />
                                }
                                {
                                    typeof file === "object" && file.mimeType?.startsWith('image/') &&
                                        <img src={file.content} alt={file.name} className="upload-image" />
                                }
                                {
                                    typeof file === "object" && !file.mimeType?.startsWith('image/') && <>
                                        <i className="fa fa-2x fa-fw fa-file"></i><br />
                                        <span className="file-name">{file.name}</span>
                                    </>
                                }
                            </a>
                        </div>
                    ))
                }
            </div>
        }
    </div>;
};