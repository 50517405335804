import React from 'react';
import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router';

interface TabProps {
    title: string;
    badge?: number;
    children: ReactNode;
};

export const Tab = ({ children }: TabProps) => {
    return <>{children}</>;
};

interface TabsProps {
    children: React.ReactElement<TabProps>[];
};

export const Tabs = ({ children }: TabsProps) => {
    const tabs = children.filter(x => x.type !== React.Fragment);

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(() => {
        const search = decodeURIComponent(location.hash.slice(1));
        const tabIndex = tabs.findIndex(x => x.props.title === search);
        return tabIndex !== -1 ? tabIndex : 0;
    });


    // TODO: Add this back
    // if (tabs.some(x => x.type !== Tab)) {
    //     throw new Error('Tabs must only contain Tab components');
    // }

    return <div>
        <div className='d-sm-none' style={{ marginBottom: '-.25rem' }}>
            <div className="dropdown">
                <button className="btn btn-lg btn-primary w-100 mb-1 rounded-0 border-start-0 border-end-0"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-display="static">
                    {tabs[activeTab].props.title}
                    <i className='fa fa-caret-down position-absolute z-1' style={{ right: '1rem', top: '.7rem' }}></i>
                </button>

                <ul className="dropdown-menu" style={{ marginTop: '-.25rem' }}>
                    {
                        tabs.map((child, index) => {
                            if (child.type === React.Fragment) {
                                return <></>;
                            }
                            const { title, badge } = child.props;
                            return <li key={index} className={`dropdown-item ${activeTab === index ? 'active' : ''}`}
                                onClick={() => setActiveTab(index)}>
                                {title}
                                {!!badge && <span className="badge bg-danger rounded-pill ms-2">{badge}</span>}
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>        
        <ul className="nav nav-tabs xs-none" id="myTab" role="tablist">
            {tabs.map((child, index) => {
                if (child.type === React.Fragment) {
                    return <></>;
                }
                const { title, badge } = child.props;
                return (
                    <li className="nav-item" key={index}>
                        <a
                            className={`nav-link ${activeTab === index ? 'active' : ''}`}
                            id={`${title}-tab`}
                            data-toggle="tab"
                            href={`#${title}`}
                            role="tab"
                            onClick={() => setActiveTab(index)}
                        >
                            {title}
                            { !!badge && <span className="badge bg-danger rounded-pill ms-2">{badge}</span> }
                        </a>
                    </li>
                );
            })}
        </ul>
        <div className="tab-content" id="myTabContent">
            {tabs.map((child, index) => {
                if (child.type === React.Fragment) {
                    return <></>;
                }
                const { title } = child.props;
                return (
                    <div
                        className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                        id={title}
                        role="tabpanel"
                        key={index}
                    >
                        {child.props.children}
                    </div>
                );
            })}
        </div>
    </div>;
};