import { useEffect, useState } from 'react';
import { getQuestionnaire, listQuestionSets, revertPendingQuestionnaireEdits, saveProject, saveQuestionnaireEntry, saveQuestionnaireSubmission } from '../../../api';
import { QuestionSet, Questionnaire, QuestionnaireSubmission, QuestionnaireType } from '../../../models';

import { printRelativeDate } from '../../../util';
import { Accordion, AccordionItem, Blocker, Card, HamburgerMenu } from '../../common';
import { ElementViewer } from '../../shared';
import { ProjectTabProps } from '../projectViewer';
import { useAppContext, useDialog } from '../../../hooks';

export const ProjectDetailsTab = ({ project, setProject, questionnaireType }: ProjectTabProps & { questionnaireType: QuestionnaireType }) => {
    const [questionnaire, setQuestionnaire] = useState<Questionnaire | undefined>(undefined);
    const [allQuestionSets, setAllQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
    const [allowEdits, setAllowEdits] = useState<boolean>(false);
    const [isDraft, setIsDraft] = useState<boolean | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [includeableQuestionSets, setIncludeableQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
    const [questionnaireInView, setQuestionnaireInView] = useState<Questionnaire | QuestionnaireSubmission | undefined>(undefined);
    const viewingPreviousSubmission = questionnaireInView?.version !== questionnaire?.version;
    const [defaultQuestionSetIndex, setDefaultQuestionSetIndex] = useState<number | undefined>(0);

    const { prompt, confirm } = useDialog();
    const { user } = useAppContext();

    // TODO: Move hasPreviousSubmissions, hasMultipleSubmissionVersions and hasPendingChanges to a useEffect
    const hasPreviousSubmissions = !!questionnaire?.submissions?.length;
    const hasMultipleSubmissionVerions = hasPreviousSubmissions && questionnaire?.submissions!.length > 1;
    const hasPendingChanges = (() => {
        if (!questionnaire) return false;
        if (questionnaire.entries.some(x => x.questionnaireVersion === questionnaire.version)) {
            return true;
        }
        if (questionnaire.submissions?.length) {
            const latestSubmission = questionnaire.submissions[questionnaire.submissions.length - 1];
            if (latestSubmission.questionSetIds.toString() !== questionnaire.questionSetIds.toString()) {
                return true;
            }
        }
        return false;
    })();

    useEffect(() => {
        if (project) {
            listQuestionSets({ projectTemplateId: project.templateId }).then(setAllQuestionSets);
            getQuestionnaire(project.specificationsQuestionnaireId!).then(setQuestionnaire);
        }
    }, [project, questionnaireType]);

    useEffect(() => {
        if (questionnaire) {
            const _isDraft = !hasPreviousSubmissions || hasPendingChanges;
            setIsDraft(_isDraft);
            setAllowEdits(project.status === "In Progress" && (questionnaire.allowEdits || !!user?.roles.includes("admin")));
            setQuestionnaireInView(_isDraft ? questionnaire : questionnaire.submissions![questionnaire.submissions!.length - 1]);
        }
    }, [questionnaire]);

    useEffect(() => {
        if (!questionnaire || !allQuestionSets) return;
        setIncludeableQuestionSets(allQuestionSets.filter(x => !questionnaire.questionSetIds.includes(x.id)));
    }, [questionnaire, allQuestionSets]);

    const addQuestionSet = (questionSetId: string) => {
        const _project = project!;
        _project.specificationsSettings.questionSetIds.push(questionSetId);
        saveProject(_project).then(project => {
            if (project) {
                setProject(project);
                const _questionnaire = { ...questionnaire! };
                _questionnaire.questionSetIds = project.specificationsSettings.questionSetIds;
                setQuestionnaire(_questionnaire);
                setDefaultQuestionSetIndex(questionnaire?.questionSetIds.indexOf(questionSetId));
            }
        });
    };

    const removeQuestionSet = async (questionSetId: string) => {
        let questionSet = allQuestionSets!.find(x => x.id === questionSetId)!;
        if (await confirm(`Are you sure you want to remove ${questionSet.name} from your questionnaire?`)) {
            const _project = project!;
            _project.specificationsSettings.questionSetIds = _project.specificationsSettings.questionSetIds.filter(x => x !== questionSetId);
            saveProject(_project).then(project => {
                if (project) {
                    setProject(project);
                    const _questionnaire = { ...questionnaire! };
                    _questionnaire.questionSetIds = project.specificationsSettings.questionSetIds;
                    setQuestionnaire(_questionnaire);
                    setDefaultQuestionSetIndex(0);
                }
            });
        }
    };

    const updateQuestionnaireEntry = (questionSetId: string, elementId: string, value: any) => {
        saveQuestionnaireEntry(questionnaire!.id, {
            questionnaireVersion: questionnaire!.version,
            questionSetId,
            elementId,
            value
        }).then(entry => {
            if (entry) {
                const _questionnaire = { ...questionnaire! };
                _questionnaire.entries = _questionnaire.entries!
                    .filter(x => !(x.questionSetId === questionSetId && x.elementId === elementId));
                _questionnaire.entries.push(entry);
                setQuestionnaire(_questionnaire);
            }
        });
    };

    const submitQuestionnaire = async () => {
        if (await confirm('Are you sure you want to submit your specifications?')) {
            let notes = await prompt({ message: 'Please enter any notes you would like to include with your submission.', multiline: true }) || undefined;
            setIsSubmitting(true);
            saveQuestionnaireSubmission(questionnaire!.id, { notes }).then(questionnaire => {
                if (questionnaire) {
                    setQuestionnaire(questionnaire);
                }
                setIsSubmitting(false);
            });
        }
    };

    const revertChanges = async () => {
        if (await confirm('Are you sure you want to revert your changes? This action cannot be undone.')) {
            setIsSubmitting(true);
            revertPendingQuestionnaireEdits(questionnaire!.id).then(questionnaire => {
                if (questionnaire) {
                    setQuestionnaire(questionnaire);
                }
                setIsSubmitting(false);
            });
        }
    };

    if (!questionnaire || !allQuestionSets || !questionnaireInView) return <div>Loading...</div>;

    const hasQuestionSets = questionnaire.questionSetIds.length > 0;

    return <Blocker block={isSubmitting}>
        {
            isDraft && allowEdits &&
            <div className='row'>
                <div className="col-md-9">
                    <h2>
                        Tell us about your project…
                    </h2>
                    {
                        hasQuestionSets ?
                        <p>
                            Expand each section to answer questions about your design. Answers will save automatically. Click 'Submit' when you are finished.
                        </p>
                        :
                        <p>
                            Make a selection from the right to add to your project.
                        </p>
                    }
                </div>
                {
                    !viewingPreviousSubmission && allowEdits &&
                    <div className="col-md-3 text-end sm-mb-3">
                        {
                            hasPreviousSubmissions && hasPendingChanges &&
                            <button type="button" className='btn btn-outline-secondary' title='Revert Changes'
                                onClick={() => revertChanges()}>
                                <i className='fa fa-rotate-left'></i>
                            </button>
                        }
                        <button
                            className={"btn btn-primary" + (hasPreviousSubmissions && hasPendingChanges ? " ms-2" : " btn-lg px-5")}
                            onClick={() => submitQuestionnaire()}
                            disabled={!hasQuestionSets}>
                            {hasPreviousSubmissions ? 'Resubmit' : 'Submit'}
                        </button>
                    </div>
                }
            </div>
        }
        {
            !isDraft &&
            <div className='box inverse text-center mb-5 pb-1'>
                <h2>Your specifications have been received!</h2>
                <p>
                    Thank you for submitting your project details. We will review your submission and get back to you with any questions.
                </p>
                {
                    allowEdits &&
                    <div>
                        <button type="button" className='btn btn-primary btn-lg mb-3'
                            onClick={() => {
                                setIsDraft(true);
                                setQuestionnaireInView(questionnaire);
                            }}>
                            Make Changes
                        </button>
                    </div>
                }
            </div>
        }
        <div className='row'>
            <div className='col-md-9 sm-mb-4'>
                <div className='questionnaire'>
                    {
                        !allowEdits && questionnaireInView.questionSetIds.length === 0 &&
                        <div className='box text-center text-muted'>
                                No content to display.
                        </div>
                    }
                    <Accordion defaultIndex={defaultQuestionSetIndex}>
                        {
                            allQuestionSets?.filter(x => questionnaireInView?.questionSetIds.includes(x.id))
                                .map((questionSet, i) => (
                                    <AccordionItem key={i} title={questionSet.name}>
                                        {
                                            questionSet.elements?.length === 0 && <div className='text-muted'>No content to display.</div>
                                        }
                                        {
                                            questionSet.elements?.map((element, i) => (
                                                <div key={i} className='element'>
                                                    <ElementViewer key={i}
                                                        elementType={element.elementType}
                                                        config={element.config || {}}
                                                        value={questionnaireInView!.entries?.find(x => x.questionSetId === questionSet.id && x.elementId === element.id)?.value || ''}
                                                        onValueChange={value => updateQuestionnaireEntry(questionSet.id, element.id, value)}
                                                        isDisabled={viewingPreviousSubmission || !allowEdits} />
                                                </div>
                                            ))
                                        }
                                        {
                                            !viewingPreviousSubmission && allowEdits && !questionSet.isRequired &&
                                            <div className='mt-4 text-center'>
                                                <HamburgerMenu
                                                    type={'h-ellipsis'}
                                                    items={[
                                                        {
                                                            label: 'Remove Section',
                                                            iconClass: 'fa fa-trash',
                                                            onClick: () => removeQuestionSet(questionSet.id)
                                                        }
                                                    ]} />
                                            </div>
                                        }
                                    </AccordionItem>
                                ))
                        }
                    </Accordion>
                </div>
            </div>
            <div className='col-md-3'>
                {
                    ((isDraft && hasPreviousSubmissions) || hasMultipleSubmissionVerions) && <Card title='Versions'>
                        <ul className='nav nav-pills row'>
                            {
                                isDraft &&
                                <li className='nav-item col-md-6'>
                                    <button type="button"
                                        className={'nav-link text-sm w-100' + (!viewingPreviousSubmission ? ' active' : '')}
                                        onClick={() => setQuestionnaireInView(questionnaire)}>
                                        Draft
                                    </button>
                                </li>
                            }
                            {
                                questionnaire.submissions?.sort((a, b) => b.version - a.version)
                                    .map((submission, i) =>
                                        <li className='nav-item col-md-6' key={i}>
                                            <button type="button"
                                                key={submission.version}
                                                className={'nav-link text-sm w-100' + (submission.version === questionnaireInView?.version ? ' active' : '')}
                                                onClick={() => setQuestionnaireInView(submission)}>
                                                v{submission.version}
                                            </button>
                                        </li>)
                            }
                        </ul>
                    </Card>
                }
                {
                    viewingPreviousSubmission && <div className='text-center text-muted'>
                        {hasMultipleSubmissionVerions ? "This version was " : "Submission "} received {printRelativeDate((questionnaireInView as QuestionnaireSubmission)?.submittedOn)}.
                    </div>
                }
                {
                    !viewingPreviousSubmission && allowEdits && !!includeableQuestionSets?.length && <div className='box'>
                        <p className="lead text-center mt-2 mb-4 sm-mb-2 fa-2x">
                            Want to add sometime to your project?
                        </p>
                        <div className='d-lg-none'>
                            <div className="dropdown dropup">
                                <button className="btn btn-lg btn-outline-secondary dropdown-toggle w-100 mb-1"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-display="static">
                                    &nbsp;
                                </button>
                                <ul className="dropdown-menu">
                                    {
                                        includeableQuestionSets!.map((x, i) =>
                                            <li key={i} className='dropdown-item'
                                                onClick={() => addQuestionSet(x.id)}>
                                                {x.name}
                                            </li>)
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='border-1 md-none'>
                            <div className='row px-2'>
                                {
                                    includeableQuestionSets!.map((x, i) =>
                                        <div key={i} className='col-xl-6 p-1 d-flex'>
                                            <button type="button"
                                                className='btn btn-outline-secondary w-100 mb-1 align-self-stretch'
                                                style={{ minHeight: '50px', textTransform: 'none' }}
                                                onClick={() => addQuestionSet(x.id)}>
                                                {x.name}
                                            </button>
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </Blocker>;
};