import { useParams } from 'react-router';
import './materialSheetPrint.scss';
import { useEffect, useState } from 'react';
import { Project, Asset, MaterialSheet, Material } from '../../../models';
import { getMaterialSheet, getProject, getProjectAssets, listMaterials } from '../../../api';
import { MaterialSheetViewer } from '../../materialSheetViewer';

export const MaterialSheetPrint = () => {
    const { materialSheetId } = useParams();

    const [materialSheet, setMaterialSheet] = useState<MaterialSheet | undefined>(undefined);
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [designs, setDesigns] = useState<Asset[]>();
    const [materials, setMaterials] = useState<Material[]>();

    const getData = async () => {
        const [materialSheet, materials] = await Promise.all([
            getMaterialSheet(materialSheetId!),
            listMaterials()
        ]);
    
        setMaterialSheet(materialSheet);
        setMaterials(materials);
    
        const project = await getProject(materialSheet.projectId);
        setProject(project);
    
        const designs = await getProjectAssets(project.id, "Design");
        setDesigns(designs);
    };

    useEffect(() => {
        getData();
    }, [materialSheetId]);

    if (!materialSheet || !project || !designs || !materials) {
        return <></>;
    }

    return <div className='p-5'>
        <MaterialSheetViewer project={project}
                designs={designs}
                materialSheet={materialSheet}
                materials={materials} />
    </div>;
};