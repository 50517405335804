import { DateTime } from "luxon";

/**
    Used for type safety 
 **/
export const literal = <T>(value: T) => value;

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const generateTempId = (): string => {
    let tempId = "_";
    for (let i = 0; i < 8; i++) {
        tempId += Math.floor(Math.random() * 16).toString(16);
    }
    return tempId;
}

export const isTempId = (id: string): boolean => !id || id.startsWith("_");

export const ellipsis = (text: string, maxLength: number): string => {
    if (!text) return text;

    if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
    }
    return text;
}

export const printRelativeDate = (date: Date | string | undefined): string | undefined => {
    // TODO: If more than X time ago, use actual date
    return date
        ?  DateTime.fromJSDate(new Date(date))?.toRelative()?.replace(/^in 0 seconds$/, 'just now').replace(/^0 seconds ago$/, 'just now')
        : date;
};

export const getMinutesAgo = (date: Date | string | undefined): number | undefined =>
    date ? Math.floor(0 - DateTime.fromISO(date.toString()).diffNow('minutes').as('minutes')) : undefined;

export const areEqual = (a: any, b: any) => (!a && !b) || (JSON.stringify(a) === JSON.stringify(b));