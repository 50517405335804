import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import './projectList.scss';
import { cancelProject, completeProject as completeProject, convertIntakeToProject, deleteProject, forceProjectSync, listProjectTemplates, listProjects, listQuestionSets, reopenProject, saveProject } from '../../../api';
import { Project, ProjectIntake, ProjectStatus, ProjectStep, ProjectTemplate, QuestionSet } from '../../../models';
import { Blocker, CheckboxListDropdown, Drawer, HamburgerMenu, MenuItem, Modal, ProgressMeter } from '../../common';
import { generateTempId } from '../../../util';
import { Link } from 'react-router-dom';
import { useDialog } from '../../../hooks';

interface StepInfo {
    id: string;
    label: string;
    number: number;
    totalNumber: number;
    manualStep: boolean;
}

export const ProjectList = () => {
    const [projects, setProjects] = useState<Project[] | undefined>(undefined);
    const [projectTemplates, setProjectTemplates] = useState<ProjectTemplate[] | undefined>(undefined);
    const [projectStepInfo, setProjectStepInfo] = useState<{ [key: string]: StepInfo } | undefined>(undefined);
    const [questionSets, setQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
    const [recentProjects, setRecentProjects] = useState<Project[] | undefined>(undefined);

    const navigate = useNavigate();
    const { confirm, prompt } = useDialog();

    const [filteredProjects, setFilteredProjects] = useState<Project[] | undefined>(undefined);
    const [search, setSearch] = useState<string>('');
    const [filteredStatuses, setFilteredStatuses] = useState<ProjectStatus[]>(['In Progress', 'Completed', 'Canceled']);

    const [editingProject, setEditingProject] = useState<Project | undefined>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);

    const location = useLocation();

    const searchPattern = new RegExp(search, 'i');

    const newProjectFromIntake = (intake: ProjectIntake) => {
        const project: Project = {
            id: generateTempId(),
            ownerName: intake.primaryContact.name,
            address: intake.address,
            fiscalYear: new Date().getFullYear(),
            referenceNumber: '',
            description: intake.projectDescription,
            specificationsSettings: {
                allowEdits: true,
                questionSetIds: intake.scopeOfWork
            },
            userEmails: [],
            templateId: '',
            completedStepIds: []
        };

        if (intake.primaryContact?.email) {
            project.userEmails.push(intake.primaryContact.email);
        }

        if (intake.secondaryContact?.email) {
            project.userEmails.push(intake.secondaryContact.email);
        }

        setEditingProject(project);
    };

    const onCancelProject = async (project: Project) => {
        if (!await confirm('Are you sure you want to cancel this project?')) return;
        const reason = await prompt({
            message: 'Please provide a reason for canceling this project.',
            multiline: true,
            entryRequired: true,
            allowCancel: true
        });
        if (reason) {
            await cancelProject(project.id, reason).then(project => {
                setProjects(projects!.map(x => x.id === project.id ? { ...x, status: project.status } : x));
            });
        }
    };

    const onCompleteProject = async (project: Project) => {
        if (await confirm('Are you sure you want to mark this project as completed?')) {
            completeProject(project.id).then(project => {
                setProjects(projects!.map(x => x.id === project.id ? { ...x, status: project.status } : x));
            });
        }
    };

    const onReopenProject = async (project: Project) => {
        if (await confirm('Are you sure you want to reopen this project?')) {
            reopenProject(project.id).then(project => {
                setProjects(projects!.map(x => x.id === project.id ? { ...x, status: project.status } : x));
            });
        }
    };

    const onDeleteProject = async (project: Project) => {
        const confirmation = await prompt({
            message: `Are you sure you want to delete this project? All associated assets will be deleted. This action cannot be undone.
            If you are sure, type the owner\'s name (${project.ownerName}) to confirm.`,
            allowCancel: true
        })
        if (confirmation === project.ownerName) {
            deleteProject(project.id).then(() => {
                setProjects(projects!.filter(x => x.id !== project.id));
            });
        } else if (confirmation) {
            alert('Owner name does not match. Project was not deleted.');
        }
    }

    useEffect(() => {
        listProjects().then(setProjects);
        listProjectTemplates().then((templates) => {
            setProjectTemplates(templates);

            const projectStepNames = templates
                .flatMap(template =>
                    template.steps.map(step => ({
                        ...step,
                        templateStepCount: template.steps.length
                    }))
                )
                .reduce((obj: any, step) => {
                    obj[step.id] = {
                        id: step.id,
                        label: step.name,
                        number: step.number,
                        totalNumber: step.templateStepCount,
                        manualStep: !step.trigger
                    };
                    return obj;
                }, {});

            setProjectStepInfo(projectStepNames);
        });

        listQuestionSets().then(setQuestionSets);

        if (location.state?.intake) {
            newProjectFromIntake(location.state.intake);
        }
    }, []);

    useEffect(() => {
        const recentProjectIds = JSON.parse(localStorage.getItem('recentProjects') || '[]') as string[];
        if (recentProjectIds.length) {
            const _recentProjects = projects?.filter(x => recentProjectIds.includes(x.id));
            setRecentProjects(_recentProjects);
        }

    }, [projects]);

    useEffect(() => {
        if (projects) {
            setFilteredProjects(projects
                .filter(x => !search || x.ownerName.match(searchPattern) || x.address.street.match(searchPattern) || x.referenceNumber.match(searchPattern))
                .filter(x => filteredStatuses.includes(x.status!)));
        }

    }, [projects, search, filteredStatuses]);


    const newProject = () => {
        const project: Project = {
            id: generateTempId(),
            ownerName: '',
            address: {
                street: '',
                city: '',
                state: 'TX',
                postal: ''
            },
            fiscalYear: new Date().getFullYear(),
            referenceNumber: '',
            description: '',
            specificationsSettings: {
                allowEdits: true,
                questionSetIds: []
            },
            userEmails: [],
            templateId: '',
            completedStepIds: []
        };

        setEditingProject(project);
    };

    const completeProjectEdit = () => {
        setIsSaving(true);
        saveProject(editingProject!).then(async (project) => {
            if (project) {
                const _projects = [...projects!];
                const index = _projects.findIndex(x => x.id === project!.id);
                if (index > -1) {
                    _projects[index] = project;
                } else {
                    _projects.push(project!);
                }
                setProjects(_projects);
                setEditingProject(undefined);
                if (location.state?.intake) {
                    convertIntakeToProject(location.state.intake.id, project.id);
                }

                const template = projectTemplates?.find(x => x.id === project.templateId);
                if (project.status === 'In Progress') {
                    const remainingSteps = template?.steps.filter(x => !project.completedStepIds.includes(x.id));
                    if (remainingSteps?.length === 0) {
                        if (await confirm('All steps have been completed. Would you like to mark this project as completed?')) {
                            completeProject(project.id).then(project => {
                                setProjects(_projects!.map(x => x.id === project.id ? project : x));
                            });
                        }
                    }
                }
            }
            setIsSaving(false);
        });
    }

    if (!projects || !projectTemplates || !questionSets) return <div>Loading Projects...</div>;

    return <div className={isNonBlockingProcess ? 'wait' : ''}>
        <div className="float-end text-end">
            <button type="button"
                className="btn btn-primary"
                onClick={() => newProject()}>
                New Project
            </button>

            {
                    !!recentProjects?.length &&
                    <div className='box mt-3 text-end md-none'>
                        <h5>Recently Viewed</h5>
                        {
                            recentProjects.map(project =>
                            <div>
                                <Link to={`/admin/projects/${project.key}`}><strong>{ project.ownerName }</strong> | { project.address.street }</Link>
                            </div>)
                        }
                    </div>
                }

        </div>
        <h1>Current Projects</h1>
        {
            !projects?.length &&
            <div className='box mt-4 text-center'>
                There are no projects yet. Click the "New Project" button to get started.
            </div>
        }
        {
            projects?.length > 0 &&
            <>
                <div className='row my-4'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label htmlFor="search">
                                Search
                            </label>
                            <div className="input-group">
                                <input type="text"
                                    id="search"
                                    className="form-control"
                                    placeholder="Name, Address, or Project #"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)} />
                                {
                                    !!search && <button type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setSearch('')}>
                                        <i className='fa fa-times'></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <CheckboxListDropdown
                            label='Status'
                            options={[
                                { label: 'In Progress', value: 'In Progress' },
                                { label: 'Completed', value: 'Completed' },
                                { label: 'Canceled', value: 'Canceled' }
                            ]}
                            values={filteredStatuses}
                            onChange={x => setFilteredStatuses(x as ProjectStatus[])} />
                    </div>
                </div>
                <div className="clearfix"></div>
                {/* HACK: the padding is a hack to prevent dropdowns from clipping */}
                {
                    !filteredProjects?.length &&
                    <div className='box mt-4 text-center'>
                        No projects match the current filter criteria.
                    </div>
                }
                {
                    !!filteredProjects?.length &&
                    <div className='table-responsive' style={{ paddingBottom: !!search ? '18rem' : '0' }}>
                        <table className='table table-striped nowrap-headers nowrap-columns align-middle'>
                            <thead>
                                <tr>
                                    <th>Project #</th>
                                    <th>Owner Name</th>
                                    <th>Address</th>
                                    <th>Type</th>
                                    <th className='text-center'>Materials</th>
                                    <th className='text-center'>Current Step / Status</th>
                                    <th style={{ width: 0 }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredProjects.map(project => {
                                        const stepInfo = projectStepInfo![project.currentStepId!];

                                        const menu: MenuItem[] = [
                                            { label: 'View', iconClass: 'fa fa-fw fa-eye', onClick: () => navigate(`/admin/projects/${project.key}`) },
                                            { type: 'divider' },
                                            {
                                                label: 'Force Sync', iconClass: 'fa fa-fw fa-right-left', onClick: () => {
                                                    setIsNonBlockingProcess(true);
                                                    forceProjectSync(project.id).then(() => {
                                                        setIsNonBlockingProcess(false);
                                                    });
                                                }
                                            },
                                            { label: 'Edit', iconClass: 'fa fa-fw fa-pencil', onClick: () => setEditingProject(project) },
                                            { label: `Material Sheets (${project.materialSheetDrafts || 0})`, iconClass: 'fa fa-fw fa-layer-group', onClick: () => navigate(`${project.key}/material-sheets`) },
                                            { 'type': 'divider' }
                                        ];

                                        if (project.status === 'In Progress') {
                                            if (!stepInfo) {
                                                menu.push({ label: 'Complete Project', iconClass: 'fa fa-fw fa-check', onClick: () => onCompleteProject(project) });
                                            }

                                            menu.push({ label: 'Cancel Project', iconClass: 'fa fa-fw fa-ban', onClick: () => onCancelProject(project) });
                                            menu.push({ label: 'Delete Project', iconClass: 'fa fa-fw fa-trash', onClick: () => onDeleteProject(project) });
                                        } else {
                                            menu.push({ label: 'Reopen Project', iconClass: 'fa fa-fw fa-undo', onClick: () => onReopenProject(project) });
                                        }

                                        return <tr key={project.id}>
                                            <td>{project.referenceNumber}</td>
                                            <td>
                                                <Link to={`/admin/projects/${project.key}`}
                                                    title="View">
                                                    {project.ownerName}
                                                </Link>
                                            </td>
                                            <td>
                                                {project.address.street}<br />
                                                {project.address.city}, {project.address.state} {project.address.postal}
                                            </td>
                                            <td>
                                                {projectTemplates.find(x => x.id === project.templateId)?.name}
                                            </td>
                                            <td className='text-center'>
                                                {project.materialSheetDrafts ?
                                                    <span className='badge text-white bg-info rounded-5 clickable'
                                                        onClick={() => navigate(`${project.key}/material-sheets`)}>
                                                        {project.materialSheetDrafts}
                                                    </span> : ''}
                                            </td>
                                            <td className='text-center'>
                                                {
                                                    project.status === 'In Progress' && !stepInfo &&
                                                    <button className='btn btn-sm btn-success' onClick={() => onCompleteProject(project)}>Complete Project</button>}
                                                {
                                                    project.status === 'In Progress' && stepInfo &&
                                                    <>
                                                        <div className='text-start'>
                                                            <label htmlFor={`current_step_${project.id}`}>
                                                                <input id={`current_step_${project.id}`} type='checkbox' disabled={!stepInfo.manualStep} onChange={async e => {
                                                                    if (e.target.checked) {
                                                                        if (await confirm(`Are you sure you want to mark this project as completed for step ${stepInfo.number}?`)) {
                                                                            const _project = { ...project };
                                                                            _project.completedStepIds.push(stepInfo.id);
                                                                            saveProject(_project).then(project => {
                                                                                setProjects(projects!.map(x => x.id === project.id ? project : x));
                                                                                e.target.checked = false;
                                                                            });
                                                                        } else {
                                                                            e.target.checked = false;
                                                                        }
                                                                    }
                                                                }} />
                                                                {stepInfo.label}
                                                            </label>
                                                        </div>
                                                        <ProgressMeter percentage={(stepInfo.number - 1) / stepInfo.totalNumber} />
                                                    </>
                                                }
                                                {
                                                    project.status === 'Completed' &&
                                                    <span className='badge bg-success'>Completed</span>
                                                }
                                                {
                                                    project.status === 'Canceled' &&
                                                    <span className='badge bg-danger'>Canceled</span>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                <HamburgerMenu type={'v-ellipsis'} size='sm' items={menu} />
                                            </td>
                                        </tr>;
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </>
        }
        {
            editingProject &&
            <ProjectEditor
                project={editingProject}
                projectTemplates={projectTemplates}
                questionSets={questionSets}
                setProject={setEditingProject}
                onSave={() => completeProjectEdit()}
                onCancel={() => setEditingProject(undefined)}
                isSaving={isSaving} />
        }
    </div>;
}

export interface ProjectEditorProps {
    project: Project;
    projectTemplates: ProjectTemplate[];
    questionSets: QuestionSet[];
    setProject: (project: Project) => void;
    onSave: () => void;
    onCancel: () => void;
    isSaving?: boolean;
}

export const ProjectEditor = ({ project, projectTemplates, questionSets, setProject, onSave, onCancel, isSaving }: ProjectEditorProps) => {
    const [selectedProjectTemplate, setSelectedProjectTemplate] = useState<ProjectTemplate | undefined>(undefined);
    const [filteredQuestionSets, setFilteredQuestionSets] = useState<QuestionSet[] | undefined>(undefined);
    const [editingUsers, setEditingUsers] = useState(false);

    const { alert } = useDialog();

    useEffect(() => {
        if (project?.templateId && projectTemplates) {
            setSelectedProjectTemplate(projectTemplates.find(x => x.id === project.templateId));
        } else {
            setSelectedProjectTemplate(undefined);
        }
    }, [project?.templateId, projectTemplates]);

    useEffect(() => {
        if (project?.templateId && questionSets) {
            const _filteredQuestionSets = questionSets.filter(x => x.allowForAllProjectTemplates || x.projectTemplateIds?.includes(project.templateId));
            setFilteredQuestionSets(_filteredQuestionSets);

            let _project = { ...project };

            // add any required question sets that are not already included
            const requiredQuestionSetIds = _filteredQuestionSets.filter(x => x.isRequired).map(x => x.id);
            requiredQuestionSetIds.forEach(x => {
                if (!_project.specificationsSettings.questionSetIds.includes(x)) {
                    _project.specificationsSettings.questionSetIds.push(x);
                }
            });

            setProject(_project);
        } else {
            setFilteredQuestionSets(undefined);
        }
    }, [project?.templateId, questionSets]);

    const validate = () => {
        // TODO: Make this a framework!

        if (!project?.ownerName) {
            alert('Owner Name is required.');
            return false;
        }
        if (!project?.address.street) {
            alert('Street is required.');
            return false;
        }
        if (!project?.address.city) {
            alert('City is required.');
            return false;
        }
        if (!project?.address.state) {
            alert('State is required.');
            return false;
        }
        if (!project?.address.postal) {
            alert('Postal is required.');
            return false;
        }
        if (!project?.referenceNumber) {
            alert('Project # is required.');
            return false;
        }
        if (!project?.description) {
            alert('Description is required.');
            return false;
        }
        if (!project?.fiscalYear) {
            alert('Fiscal Year is required.');
            return false;
        }
        if (!project?.templateId) {
            alert('Project Template is required.');
            return false;
        }

        return true;
    };

    return <Drawer title='Project Editor' size='xl'
        confirmText='Save'
        onConfirm={() => {
            if (validate()) {
                onSave();
            }
        }}
        onCancel={() => onCancel()}>
        <Blocker block={isSaving}>
            <div className="box">
                <div className="row">
                    <div>
                        <button
                            className='btn btn-outline-primary btn-sm float-end'
                            onClick={() => setEditingUsers(true)}>
                            Manage Users ({project?.userEmails?.length || 0})
                        </button>
                        <h4 className='mb-3'>Property / Owner Data</h4>
                        <div className="clearfix"></div>
                    </div>
                    <div className="form-group col-12">
                        <label>Owner Name</label>
                        <input type='text'
                            className="form-control"
                            value={project?.ownerName}
                            onChange={e => setProject({ ...project, ownerName: e.target.value })} />
                    </div>

                    <div className="form-group col-12">
                        <label>Street</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.street}
                            onChange={e => setProject({ ...project, address: { ...project.address, street: e.target.value } })}
                            autoComplete='not-street' />
                    </div>
                    <div className="form-group col-md-7">
                        <label>City</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.city}
                            onChange={e => setProject({ ...project, address: { ...project.address, city: e.target.value } })}
                            autoComplete='not-city' />
                    </div>
                    <div className="form-group col-md-2">
                        <label>State</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.state}
                            onChange={e => setProject({ ...project, address: { ...project.address, state: e.target.value } })}
                            autoComplete='not-state' />
                    </div>
                    <div className="form-group col-md-3">
                        <label>Postal</label>
                        <input type='text'
                            className="form-control"
                            value={project?.address.postal}
                            onChange={e => setProject({ ...project, address: { ...project.address, postal: e.target.value } })}
                            autoComplete='not-postal' />
                    </div>
                </div>
            </div>
            <div className='box'>
                <div className='row'>
                    <h4 className="mb-3">Project Information</h4>
                    <div className="form-group col-md-4">
                        <label>Project #</label>
                        <input type='text'
                            className="form-control"
                            value={project?.referenceNumber}
                            onChange={e => setProject({ ...project, referenceNumber: e.target.value })} />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Project Description</label>
                        <input type='text'
                            className="form-control"
                            value={project?.description}
                            onChange={e => setProject({ ...project, description: e.target.value })} />
                    </div>
                    <div className="form-group col-md-2">
                        <label>Fiscal Year</label>
                        <input type='text'
                            className="form-control"
                            value={project?.fiscalYear}
                            onChange={e => setProject({ ...project, fiscalYear: +e.target.value })} />
                    </div>
                    <div className='form-group col-md-4'>
                        <label>Project Template</label>
                        <select className='form-control'
                            value={project?.templateId}
                            onChange={e => setProject({ ...project, templateId: e.target.value })}>
                            <option></option>
                            {
                                projectTemplates?.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-md-8">
                        {
                            selectedProjectTemplate &&
                            <CheckboxListDropdown
                                label='Steps Completed'
                                options={selectedProjectTemplate?.steps.map(x => ({ label: x.name, value: x.id, isDisabled: !!x.trigger })) || []}
                                values={project?.completedStepIds || []}
                                onChange={x => setProject({ ...project, completedStepIds: x })}
                                columns={1} />
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {
                        selectedProjectTemplate && selectedProjectTemplate.questionnaires?.includes('Design Specifications') &&
                        <div className='box'>
                            <div className='row'>
                                <div className="col-12">
                                    <h4 className='mb-3'>Design Specifications</h4>
                                </div>
                                <div className="col-12">
                                    <div className='from-group'>
                                        <CheckboxListDropdown
                                            label=''
                                            options={filteredQuestionSets?.map(x => ({ label: x.name, value: x.id, isDisabled: x.isRequired })) || []}
                                            values={project?.specificationsSettings.questionSetIds || []}
                                            onChange={x => setProject({ ...project, specificationsSettings: { ...project.specificationsSettings, questionSetIds: x } })}
                                            columns={1} />
                                    </div>
                                    <div className='from-group'>
                                        <label>
                                            <input type='checkbox'
                                                checked={project?.specificationsSettings.allowEdits}
                                                onChange={e => setProject({ ...project, specificationsSettings: { ...project.specificationsSettings, allowEdits: e.target.checked } })} />
                                            Editing Enabled
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                editingUsers &&
                <Modal title='Project Users' onConfirm={() => {
                    setProject({ ...project, userEmails: project.userEmails.filter(x => x !== '') });
                    setEditingUsers(false);
                }}>
                    <UserEmailsEntry
                        userEmails={project.userEmails || []}
                        setUserEmails={x => setProject({ ...project, userEmails: x })} />
                </Modal>
            }
        </Blocker>
    </Drawer>;
}

interface UserEmailsEntryProps {
    userEmails: string[];
    setUserEmails: (userEmails: string[]) => void;
}

const UserEmailsEntry = ({ userEmails, setUserEmails }: UserEmailsEntryProps) => {
    return <>
        <div className="text-muted mb-3 text-sm">
            Click below to add users to the project. Each user will receive an email invitation to join the project.
        </div>
        {
            !!userEmails.length && <div className="form-group">
                {userEmails.map((x, index) => <div key={index}>
                    <div className="input-group mb-2">
                        <input type="email"
                            autoComplete='not-email'
                            className="form-control"
                            value={x}
                            onChange={e => {
                                const newUserEmails = [...userEmails];
                                newUserEmails[index] = e.target.value;
                                setUserEmails(newUserEmails);
                            }} />
                        <button type="button" className="input-group-text"
                            onClick={() => setUserEmails(userEmails.filter((_, i) => i !== index))}>
                            <i className="fa fa-trash"></i>
                        </button>
                    </div>
                </div>)}
            </div>
        }
        <div className="text-center">
            <button type="button"
                onClick={() => setUserEmails([...userEmails, ''])}
                className="btn btn-outline-primary btn-sm"
                disabled={userEmails[userEmails.length - 1] === ''}>
                {userEmails.length === 0 ? 'Add User' : 'Add Another User'}
            </button>
        </div>
    </>;
}
