import { useEffect, useState } from "react";
import { Material, MaterialOption, MaterialProperty } from "../../../models";
import { deleteMaterial, listMaterials, saveMaterial } from "../../../api";
import { Drawer, HamburgerMenu, Modal } from "../../common";
import { useDialog } from "../../../hooks";
import { generateTempId } from "../../../util";
import { FileUploadElement } from "../../shared";

export const MaterialList = () => {

    const { confirm } = useDialog();

    const [materials, setMaterials] = useState<Material[]>([]);

    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);

    const [selectedMaterial, setSelectedMaterial] = useState<Material | undefined>(undefined);

    useEffect(() => {
        listMaterials().then((materials) => {
            setMaterials(materials);
        });
    }, []);

    if (!materials) {
        return <div>Loading...</div>;
    }

    return <div>
        <div className="float-end">
            <button className="btn btn-primary"
                onClick={() => setSelectedMaterial({
                    id: generateTempId(),
                    category: '',
                    name: '',
                    unitOfMeasure: '',
                    properties: []
                })}>
                Add Material
            </button>
        </div>
        <h1>Materials</h1>
        <div className="clearfix"></div>
        <div className="form-group col-md-6">
            <label>Category</label>
            <select className="form-control"
                onChange={e => {
                    setSelectedCategory(e.target.value);
                }}
            >
                <option value="">All Categories</option>
                {
                    Array.from(new Set(materials.map(x => x.category))).map((category) => {
                        return <option key={category} value={category}>{category}</option>;
                    })
                }
            </select>
        </div>
        {/* HACK: the padding is a hack to prevent dropdowns from clipping */}
        <div className='table-responsive' style={{ paddingBottom: !!selectedCategory ? '10rem' : '0' }}>
            <table className="table table-condensed table-striped nowrap-headers nowrap-columns">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Name</th>
                        <th>Unit of Measure</th>
                        <th className="text-center">Properties</th>
                        <th style={{ width: 0 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {materials.filter(x => !selectedCategory || x.category === selectedCategory).map((material) => {
                        return <tr key={material.id}>
                            <td>{material.category}</td>
                            <td>{material.name}</td>
                            <td>{material.unitOfMeasure}</td>
                            <td className="text-center">{material.properties.length}</td>
                            <td className="text-end">
                                <HamburgerMenu type={'v-ellipsis'} size='sm' items={[
                                    { label: 'Edit', iconClass: 'fa fa-fw fa-pencil', onClick: () => setSelectedMaterial(material) },
                                    {
                                        label: 'Delete', iconClass: 'fa fa-fw fa-trash', onClick: async () => {
                                            const confirmed = await confirm(`Are you sure you want to delete ${material.name}?`);
                                            if (confirmed) {
                                                deleteMaterial(material.id).then(() => {
                                                    setMaterials(materials.filter(x => x.id !== material.id));
                                                });
                                            }
                                        }
                                    },
                                ]} />
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
        {
            selectedMaterial && <MaterialEditor
                material={selectedMaterial}
                categoryNames={Array.from(new Set(materials.map(x => x.category)))}
                onSave={(material => {
                    saveMaterial(material).then(() => {
                        listMaterials().then((materials) => {
                            setMaterials(materials);
                        });
                        setSelectedMaterial(undefined);
                    });
                })}
                onCancel={() => setSelectedMaterial(undefined)}
            />
        }
    </div>;
};

interface MaterialEditorProps {
    material: Material;
    categoryNames: string[];
    onSave: (material: Material) => void;
    onCancel: () => void;
}

const MaterialEditor = ({ material, categoryNames, onSave, onCancel }: MaterialEditorProps) => {
    const { confirm } = useDialog();
    const [tempMaterial, setTempMaterial] = useState<Material>({ ...material });

    const [editingProperty, setEditingProperty] = useState<MaterialProperty | undefined>(undefined);

    const onDelete = async (property: MaterialProperty) => {
        const confirmed = await confirm(`Are you sure you want to delete ${property.name}?`);
        if (confirmed) {
            const newProperties = tempMaterial.properties.filter(x => x.id !== property.id);
            setTempMaterial({ ...tempMaterial, properties: newProperties });
        }
    };

    return <Drawer title={`Configure ${material.name || "Material"}`}
        onConfirm={() => onSave(tempMaterial)}
        onCancel={() => onCancel()}
        size="lg">
        <div className="row">
            <div className="form-group col-12">
                <label>Category</label>

                <input type="text"
                    list={`data-list-${material.id}`}
                    className="form-control"
                    value={tempMaterial.category}
                    onChange={e => setTempMaterial({ ...tempMaterial, category: e.target.value })} />
                <datalist id={`data-list-${material.id}`}>
                    {
                        categoryNames.map(x => <option key={x} value={x} />)
                    }
                </datalist>
            </div>
            <div className="form-group col-md-8">
                <label>Name</label>
                <input type='text'
                    className="form-control"
                    value={tempMaterial.name}
                    onChange={e => setTempMaterial({ ...tempMaterial, name: e.target.value })} />
            </div>
            <div className="form-group col-md-4">
                <label>Unit of Measure</label>
                <input type='text'
                    className="form-control"
                    value={tempMaterial.unitOfMeasure}
                    onChange={e => setTempMaterial({ ...tempMaterial, unitOfMeasure: e.target.value })} />
            </div>
            <hr />
            <div className="px-3">
                <h5>Properties</h5>
                {
                    tempMaterial.properties.length === 0 &&
                    <div className="box text-muted text-center text-sm">No properties added.</div>
                }
                {
                    tempMaterial.properties.length > 0 &&
                    <table className="table table-condensed table-striped align-middle">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="text-center">Options</th>
                                <th style={{ width: 0 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tempMaterial.properties.map((property, index) => {
                                    return <tr key={property.id}>
                                        <td>
                                            {property.name}
                                        </td>
                                        <td className="text-center">
                                            {property.options?.length}
                                        </td>
                                        <td className='text-end'>
                                            <HamburgerMenu type={'v-ellipsis'} size='sm' items={[
                                                { label: 'Edit', iconClass: 'fa fa-fw fa-pencil', onClick: () => setEditingProperty(property) },
                                                { label: 'Delete', iconClass: 'fa fa-fw fa-trash', onClick: () => onDelete(property) },
                                            ]} />
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                }
                <button className="btn btn-outline-primary w-100"
                    onClick={() => setEditingProperty({
                        id: generateTempId(),
                        name: '',
                        options: []
                    })}>
                    Add Property
                </button>
            </div>
        </div>
        {
            editingProperty && <MaterialPropertyEditor
                property={editingProperty}
                onSave={(property) => {
                    const newProperties = tempMaterial.properties.map(x => x.id === property.id ? property : x);
                    if (!tempMaterial.properties.find(x => x.id === property.id)) {
                        newProperties.push(property);
                    }
                    setTempMaterial({ ...tempMaterial, properties: newProperties });
                    setEditingProperty(undefined);
                }}
                onCancel={() => setEditingProperty(undefined)}
            />
        }
    </Drawer>;
};

interface MaterialPropertyEditorProps {
    property: MaterialProperty;
    onSave: (property: MaterialProperty) => void;
    onCancel: () => void;
}

const MaterialPropertyEditor = ({ property, onSave, onCancel }: MaterialPropertyEditorProps) => {
    const [tempProperty, setTempProperty] = useState<MaterialProperty>({ ...property });
    const [batchEntry, setBatchEntry] = useState<boolean>(false);
    const [editingOption, setEditingOption] = useState<MaterialOption | undefined>(undefined);

    return <Modal title={`Configure ${property.name || "Property"}`}
        onConfirm={() => onSave(tempProperty)}
        onCancel={() => onCancel()}
        size="md">
        <div className="form-group">
            <label>Name</label>
            <input type='text'
                className="form-control"
                value={tempProperty.name}
                onChange={e => setTempProperty({ ...tempProperty, name: e.target.value })} />
        </div>
        <div className="form-group">
            <div className="float-end">
                <label className="text-sm" onClick={() => setBatchEntry(!batchEntry)}>
                    <i className={batchEntry ? "fa fa-toggle-on me-1" : "fa fa-toggle-off me-1"}></i>
                    Batch Edit
                </label>
            </div>
            <label>Options</label>
            <div className="clearfix"></div>
            {
                !batchEntry &&
                <div className="options-container">
                    {
                        tempProperty.options.length === 0 &&
                        <div className="text-muted text-center text-sm">No options added.</div>
                    }
                    {
                        tempProperty.options.map((option, index) => {
                            return <div key={index} className="mb-1">
                                <div className="input-group mb-0">
                                    <span className="input-group-text">
                                        <i className={"fa fa-image" + (option.imageUrl ? " " : " opacity-25")}
                                            onClick={() => {
                                                setEditingOption(option);
                                            }}></i>
                                    </span>
                                    <input type="text"
                                        id={'option-field-' + index}
                                        className="form-control"
                                        value={option.name}
                                        onChange={e => {
                                            const newOptions = tempProperty.options.map((x, i) => i === index ? { ...x, name: e.target.value } : x);
                                            setTempProperty({ ...tempProperty, options: newOptions });
                                        }} />
                                    <button type="button" className="input-group-text"
                                        onClick={() => {
                                            setEditingOption(option);
                                        }}>
                                        <i className="fa-solid fa-angles-right"></i>
                                    </button>
                                    <button type="button" className="input-group-text"
                                        onClick={() => {
                                            const newOptions = tempProperty.options.filter((x, i) => i !== index);
                                            setTempProperty({ ...tempProperty, options: newOptions });
                                        }}>
                                        <i className="fa fa-trash text-danger"></i>
                                    </button>
                                </div>
                            </div>;
                        })
                    }
                </div>
            }
            {
                batchEntry &&
                <>
                    <textarea className="form-control"
                        rows={10}
                        value={tempProperty.options.map(o => o.name).join('\n')}
                        onChange={e => {
                            const revisedOptions: MaterialOption[] = [];
                            e.target.value.split('\n').forEach((name) => {
                                const existing = tempProperty.options.find(x => x.name === name);
                                if (existing) {
                                    revisedOptions.push(existing);
                                } else {
                                    revisedOptions.push({ id: generateTempId(), name });
                                }
                            });
                            setTempProperty({ ...tempProperty, options: revisedOptions });
                        }} />
                </>
            }
            <button type="button" className="btn btn-outline-primary btn-sm mt-2 w-100"
                onClick={() => setTempProperty({ ...tempProperty, options: [...tempProperty.options, { id: generateTempId(), name: '' }] })}>
                Add Option
            </button>
            {
                editingOption && <AdvanceOptionEditor
                    option={editingOption}
                    onSave={(option) => {
                        const newOptions = tempProperty.options.map(x => x.id === option.id ? option : x);
                        if (!tempProperty.options.find(x => x.id === option.id)) {
                            newOptions.push(option);
                        }
                        setTempProperty({ ...tempProperty, options: newOptions });
                        setEditingOption(undefined);
                    }}
                    onCancel={() => {
                        setEditingOption(undefined);
                    }} />
            }
        </div>
    </Modal>;
};

interface AdvanceOptionEditorProps {
    option: MaterialOption;
    onSave: (property: MaterialOption) => void;
    onCancel: () => void;
}

const AdvanceOptionEditor = ({ option, onSave, onCancel }: AdvanceOptionEditorProps) => {
    const [tempOption, setTempOption] = useState<MaterialOption>({ ...option });

    return <Modal title={`Configure ${option.name || "Option"}`}
        onConfirm={() => onSave(tempOption)}
        onCancel={() => onCancel()}
        size="md">
        <div className="form-group">
            <label>Name</label>
            <input type='text'
                className="form-control"
                value={tempOption.name}
                onChange={e => setTempOption({ ...tempOption, name: e.target.value })} />
        </div>
        <div className="form-group">
            <label>Description</label>
            <textarea className="form-control"
                value={tempOption.description}
                onChange={e => setTempOption({ ...tempOption, description: e.target.value })} />
        </div>
        <div className="form-group">
            <label>Supplier</label>
            <input type='text'
                className="form-control"
                value={tempOption.manufacturer}
                onChange={e => setTempOption({ ...tempOption, manufacturer: e.target.value })} />
        </div>
        <div className="form-group">
            <FileUploadElement config={{
                prompt: 'Image',
                acceptedFileTypes: 'image/*',
                allowMultipleSelection: false
            }}
                value={tempOption.imageUrl ? [tempOption.imageUrl] : []}
                onValueChange={x => setTempOption({ ...tempOption, imageUrl: x[0].content })} />
        </div>
    </Modal>;
};