import { useEffect, useState } from 'react';
import { deleteMaterialSheet, getMaterialSheet, getProject, getProjectAssets, listMaterials, saveMaterialSheet } from '../../../api';
import { useDialog } from '../../../hooks';
import { Asset, Material, MaterialArea, MaterialElection, MaterialSheet, Project } from '../../../models';
import { areEqual, generateTempId, isTempId, printRelativeDate } from '../../../util';
import { Breadcrumb, Card, HamburgerMenu, Modal } from '../../common';
import { MaterialSheetViewer } from '../../materialSheetViewer';
import './materialSheetEditor.scss';
import { useLocation, useNavigate, useParams } from 'react-router';

export const MaterialSheetEditor = () => {
    const { projectKey, materialSheetId } = useParams();

    const [project, setProject] = useState<Project | undefined>(undefined);
    const [designs, setDesigns] = useState<Asset[]>();

    const [materialSheet, setMaterialSheet] = useState<MaterialSheet | undefined>(undefined);
    const [unchangedMaterialSheet, setUnchangedMaterialSheet] = useState<MaterialSheet | undefined>(undefined);
    const [materials, setMaterials] = useState<Material[]>();
    const [groupedMaterials, setGroupedMaterials] = useState<Record<string, Material[]>>();
    const [editingArea, setEditingArea] = useState<MaterialArea | undefined>(undefined);
    const [editingElection, setEditingElection] = useState<MaterialElection | undefined>(undefined);
    const [editingMaterial, setEditingMaterial] = useState<Material | undefined>(undefined);

    const { alert, confirm } = useDialog();
    const navigate = useNavigate();

    const hasUnsavedChanges = () => materialSheet && unchangedMaterialSheet && !areEqual(materialSheet, unchangedMaterialSheet);

    const returnToList = () => {
        navigate(`/admin/projects/${projectKey}/material-sheets`);
    }

    useEffect(() => {
        getProject(projectKey!).then(project => {
            if (project) {
                setProject(project);
                getProjectAssets(project.id, "Design").then(designs => setDesigns(designs));

                if (materialSheetId) {
                    getMaterialSheet(materialSheetId).then(materialSheet => {
                        if (materialSheet) {
                            setMaterialSheet(materialSheet);
                            setUnchangedMaterialSheet({ ...materialSheet });    
                        }
                    });
                } else {
                    setMaterialSheet({
                        id: generateTempId(),
                        projectId: project.id,
                        title: 'Materials',
                        areas: [],
                        status: 'Draft'
                    });
                }
            }
        });

        listMaterials().then(materials => {
            const groupedMaterials = materials.reduce((a, m) => {
                const { category } = m;
                if (!a[category]) {
                    a[category] = [];
                }
                a[category].push(m);
                return a;
            }, {} as Record<string, Material[]>);

            setMaterials(materials);
            setGroupedMaterials(groupedMaterials);
        });
    }, []);

    const validate = () => {
        if (!materialSheet?.title) {
            alert('Please enter a title for the material sheet.');
            return false;
        }

        if (!materialSheet.areas?.length) {
            alert('Material sheet must have at least one area.');
            return false;
        }

        return true;
    };

    const saveEdits = () => {
        if (!materialSheet) return;
        if (!validate()) return;
        saveMaterialSheet(materialSheet).then(x => {
            if (x) {
                returnToList();
            }
        });
    }

    const onDelete = async () => {
        if (await confirm('Are you sure you want to delete this material sheet?')) {
            deleteMaterialSheet(materialSheet!.id).then(() => returnToList());
        };
    }

    const addArea = async () => {
        if (!materialSheet) return;
        materialSheet.areas = materialSheet.areas || [];
        setEditingArea({ id: generateTempId(), title: '', elections: [], displayOrder: materialSheet.areas.length });
    };

    if (!project || !designs || !materialSheet || !materials) return <div>Loading...</div>;

    const lastSubmission = !!materialSheet.submissions?.length
        ? materialSheet.submissions[materialSheet.submissions.length - 1]
        : undefined;

    return <div>
        <div className='row'>
            <div className="col-12">
                <div className='float-end justify-content-end'>
                    <button type="button"
                        onClick={async () => {
                            if (hasUnsavedChanges() && !await confirm('You have unsaved changes. Are you sure you want to leave?')) {
                                return;
                            }
                            returnToList();
                        }}
                        className='btn btn-secondary ms-2'>
                        Cancel
                    </button>
                    <button type="button"
                        onClick={() => saveEdits()}
                        className='btn btn-primary ms-2'>
                        Save
                    </button>
                </div>
                <Breadcrumb items={[
                    { label: 'Projects', path: '/admin/projects' },
                    { label: project.address.street, path: `/admin/projects/${project.key}` },
                    { label: 'Material Sheets', path: `/admin/projects/${project.key}/material-sheets` },
                    { label: materialSheetId ? materialSheet.title : 'New Material Sheet' }
                ]} />
                <div className='clearfix'></div>
            </div>
            <div className='col-md-7 sm-mb-4'>
                <div className='material-sheet-edit-viewer px-4 py-4'>
                    <MaterialSheetViewer project={project}
                        designs={designs}
                        materialSheet={materialSheet}
                        materials={materials}
                        editMode={true}
                        setEditingArea={setEditingArea}
                        setEditingMaterial={setEditingMaterial}
                        setEditingElection={setEditingElection}
                        updateMaterialSheet={setMaterialSheet} />
                </div>
            </div>
            <div className='col-md-5'>
                <div className="text-center box bg-outline-secondary">
                    {project.address.street} | <strong>{project.ownerName}</strong>
                </div>
                {
                    lastSubmission &&
                    <div className="box text-sm text-muted">
                        Last submission: {printRelativeDate(lastSubmission.submittedOn)}<br />
                        {lastSubmission.submittedBy}
                    </div>
                }
                <Card title='Material Sheet Details'>
                    <div className="form-group">
                        <label>Associated Design</label>
                        <select
                            className="form-control"
                            value={materialSheet.designAssetId}
                            onChange={e => {
                                let title = designs?.find(d => d.id === e.target.value)?.versions[0].label || '';
                                if (title) {
                                    title += " Materials";
                                } else {
                                    title = 'Materials';
                                }
                                setMaterialSheet({ ...materialSheet, designAssetId: e.target.value, title: title });
                            }}>
                            <option></option>
                            {designs?.map(design => <option key={design.id} value={design.id}>{design.versions[0].label}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Title</label>
                        <input type='text'
                            className="form-control"
                            value={materialSheet.title}
                            onChange={e => setMaterialSheet({ ...materialSheet, title: e.target.value })} />
                    </div>
                    {
                        !isTempId(materialSheet.id) &&
                        <>
                            <hr className='mt-4' />
                            <div className='text-center mt-4'>
                                <HamburgerMenu
                                    type='h-ellipsis'
                                    items={[
                                        { label: 'Delete', iconClass: "fa fa-trash", onClick: () => onDelete() }
                                    ]} />
                            </div>
                        </>
                    }

                </Card>


                <Card title='Element Library'>
                    <div className='element-library'>
                        <div className='row'>
                            <div className='col-md-4 p-1'>
                                <button
                                    className={'element-button'}
                                    onClick={() => addArea()}>
                                    <i className={'fa-fw fa-solid fa-table-list'}></i>
                                    <span>Material Area</span>
                                    <span className='clearfix'></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card title='Material Library'>
                    <div className='element-library'>
                        <div>
                            {
                                groupedMaterials && Object.keys(groupedMaterials).map(category =>
                                    <div key={category}>
                                        <h5 className="mt-3">{category}</h5>
                                        <div className="row">
                                            {groupedMaterials[category].map(material =>
                                                <div key={material.id} className='col-md-4 p-1'>
                                                    <button
                                                        className={'element-button'}
                                                        onClick={() => {
                                                            if (!materialSheet.areas || !materialSheet.areas.length) {
                                                                alert('Please add an area before adding materials.');
                                                                return;
                                                            }
                                                            setEditingMaterial(material);
                                                        }}>
                                                        <i className={'fa-fw fa-solid fa-square-plus'}></i>
                                                        <span>{material.name}</span>
                                                        <span className='clearfix'></span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Card>
            </div>
        </div>
        {
            editingArea && <AreaEditor
                area={editingArea}
                onSave={(area) => {
                    materialSheet.areas = materialSheet.areas || [];
                    if (materialSheet.areas?.findIndex(x => x.id === editingArea.id) === -1) {
                        materialSheet.areas.push(area);
                    } else {
                        const index = materialSheet.areas.findIndex(x => x.id === editingArea.id);
                        materialSheet.areas[index] = area;
                    }
                    setMaterialSheet({ ...materialSheet });
                    setEditingArea(undefined);
                }}
                onCancel={() => setEditingArea(undefined)} />
        }
        {
            editingMaterial && <MaterialElectionEditor
                areas={materialSheet.areas!.map(x => x.title)}
                material={editingMaterial!}
                election={editingElection}
                onSave={(election: MaterialElection, areaName: string) => {
                    for (const area of materialSheet!.areas!) {
                        area.elections = area.elections.filter(x => x.id !== election.id);
                    }

                    const areaIndex = materialSheet!.areas!.findIndex(x => x.title === areaName);
                    const area = materialSheet!.areas![areaIndex];

                    if (election.displayOrder < 1) {
                        election.displayOrder = area.elections.length + 1;
                    }

                    area.elections.push(election);
                    area.elections = area.elections.sort((a, b) => a.displayOrder - b.displayOrder);

                    setMaterialSheet({ ...materialSheet! });
                    setEditingMaterial(undefined);
                    setEditingElection(undefined);
                }}
                onCancel={() => {
                    setEditingMaterial(undefined);
                    setEditingElection(undefined);
                }} />
        }
    </div>;
};

interface AreaEditorProps {
    area: MaterialArea;
    onSave: (area: MaterialArea) => void;
    onCancel: () => void;
}

const AreaEditor = ({ area, onSave, onCancel }: AreaEditorProps) => {
    const [tempArea, setTempArea] = useState<MaterialArea>({ ...area });

    return <Modal title="Configure Area"
        onConfirm={() => onSave(tempArea)}
        onCancel={() => onCancel()}
        size="md">
        <div className="row">
            <div className="form-group col-12">
                <label>Title</label>
                <input type='text'
                    className="form-control"
                    value={tempArea.title}
                    onChange={e => setTempArea({ ...tempArea, title: e.target.value })} />
            </div>
            <div className="form-group col-12">
                <label>Description</label>
                <textarea className="form-control"
                    value={tempArea.description}
                    onChange={e => setTempArea({ ...tempArea, description: e.target.value })}
                    rows={6}>
                </textarea>
            </div>
        </div>
    </Modal>;
};

interface MaterialElectionEditorProps {
    areas: string[];
    material: Material;
    election?: MaterialElection;
    onSave: (election: MaterialElection, areaName: string) => void;
    onCancel: () => void;
}

const MaterialElectionEditor = ({ areas, material, election, onSave, onCancel }: MaterialElectionEditorProps) => {
    const [tempArea, setTempArea] = useState<string>(areas[areas.length - 1]);
    const [tempElection, setTempElection] = useState<MaterialElection>(election ? { ...election } : {
        id: generateTempId(),
        label: '',
        materialId: material.id,
        specifications: [],
        displayOrder: -1
    });

    return <Modal title={`Configure ${material.name}` + (election?.label ? ` (${election.label})` : '')}
        onConfirm={() => onSave(tempElection, tempArea)}
        onCancel={() => onCancel()}
        size="md">
        <div className="row">
            <div className="form-group col-12">
                <label>Area</label>
                <select
                    className="form-control"
                    value={tempArea}
                    onChange={e => setTempArea(e.target.value)}>
                    {
                        areas.map(area =>
                            <option key={area} value={area}>{area}</option>
                        )
                    }
                </select>
            </div>
            <div className="form-group col-md-8">
                <label>Label</label>
                <input type='text'
                    className="form-control"
                    value={tempElection.label}
                    onChange={e => setTempElection({ ...tempElection, label: e.target.value })} />
            </div>
            {
                material.unitOfMeasure &&
                <div className="form-group col-md-4">
                    <label>Quantity ({material.unitOfMeasure})</label>
                    <input type='text'
                        className="form-control"
                        value={tempElection.quantity}
                        onChange={e => setTempElection({ ...tempElection, quantity: e.target.value })} />
                </div>
            }
            <div className="col-12">
                <label>Notes</label>
                <textarea
                    className="form-control"
                    value={tempElection.notes}
                    onChange={e => setTempElection({ ...tempElection, notes: e.target.value })} />
            </div>
        </div>
        <hr />
        <div className="row">
            {
                material.properties.map(property =>
                    <div className="form-group col-12">
                        <label>{property.name}</label>

                        <select
                            className="form-control"
                            value={tempElection.specifications.find(x => x.propertyId === property.id)?.optionId}
                            onChange={e => {
                                const optionId = e.target.value;
                                const existing = tempElection.specifications.find(x => x.propertyId === property.id);
                                if (existing) {
                                    existing.optionId = optionId;
                                } else {
                                    tempElection.specifications.push({ propertyId: property.id, optionId });
                                }
                                setTempElection({ ...tempElection });
                            }}>
                            <option></option>
                            {
                                property.options.map(option =>
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                )
                            }
                            <option value="...">Other...</option>
                        </select>
                        {
                            tempElection.specifications.find(x => x.propertyId === property.id)?.optionId === "..." &&
                            <input type='text'
                                className="form-control mt-2"
                                placeholder="Enter a value"
                                value={tempElection.specifications.find(x => x.propertyId === property.id)?.value}
                                onChange={e => {
                                    const value = e.target.value;
                                    const existing = tempElection.specifications.find(x => x.propertyId === property.id);
                                    if (existing) {
                                        existing.value = value;
                                    } else {
                                        tempElection.specifications.push({ propertyId: property.id, value });
                                    }
                                    setTempElection({ ...tempElection });
                                }} />
                        }
                    </div>
                )
            }
        </div>

    </Modal>;
};
