import { useEffect, useState, DragEvent } from 'react';
import { ElementConfig, ElementType, ProjectTemplate, QuestionSet, QuestionSetElement, QuestionnaireType } from '../../../models';
import './questionSetEditor.scss';
import { deleteQuestionSet, getQuestionSet, listProjectTemplates, saveQuestionSet } from '../../../api';
import { useNavigate, useParams } from 'react-router';
import { ElementConfigEditor } from './elementConfigtEditor';
import { ElementViewer, FileUploadElement } from '../../shared';
import { generateTempId, literal } from '../../../util';
import { Card, CheckboxListDropdown, HamburgerMenu } from '../../common';
import { useDialog } from '../../../hooks';

export const QuestionSetEditor = () => {
  const [projectTemplates, setProjectTemplates] = useState<ProjectTemplate[] | undefined>(undefined);
  const [questionSet, setQuestionSet] = useState<QuestionSet>();
  const [questionSetTitle, setQuestionSetTitle] = useState<string>('');

  const params = useParams<{ questionSetKey?: string }>();
  const { confirm, alert } = useDialog();

  const [editingElement, setEditingElement] = useState<QuestionSetElement | undefined>();
  const [draggedIndex, setDraggedIndex] = useState<number | undefined>(undefined);
  const [activeDropzone, setActiveDropzone] = useState<number | undefined>(undefined);

  const navigate = useNavigate();

  const isNewQuestionSet = !questionSet?.id || questionSet?.id.startsWith('_');

  useEffect(() => {
    listProjectTemplates().then(setProjectTemplates);

    if (params.questionSetKey) {
      getQuestionSet(params.questionSetKey).then(x => {
        setQuestionSet(x);
        setQuestionSetTitle(x.name);
      });
    } else {
      setQuestionSet({
        id: generateTempId(),
        questionnaireType: 'Design Specifications',
        displayOrder: -1,
        allowForAllProjectTemplates: true,
        name: '',
        isRequired: false,
        allowMultipleInstances: false,
        elements: []
      });
    }
  }, []);

  const onDelete = async () => {
    if (await confirm('Are you sure you want to delete this question set?')) {
      deleteQuestionSet(questionSet!.id).then(() => navigate('/admin/question-sets'));
    };
  }

  interface ElementInfo {
    elementType: ElementType;
    label: string;
    icon: string;
    skipConfig?: boolean;
    isUserEntry?: boolean;
  }

  const ELEMENT_TYPES: ElementInfo[] = [
    { elementType: 'Header', label: 'Header', icon: 'fa fa-heading' },
    { elementType: 'Text', label: 'Text', icon: 'fa fa-font' },
    { elementType: 'ImageSet', label: 'Image Set', icon: 'fa fa-images' },
    { elementType: 'Divider', label: 'Divider', icon: 'fa fa-grip-lines', skipConfig: true },
    { elementType: 'TextEntry', label: 'Text Entry', icon: 'fa fa-i-cursor', isUserEntry: true },
    { elementType: 'RadioButtons', label: 'Radio Buttons', icon: 'fa fa-circle-dot', isUserEntry: true },
    { elementType: 'Dropdown', label: 'Dropdown', icon: 'fa fa-square-caret-down', isUserEntry: true },
    { elementType: 'Checkboxes', label: 'Checkboxes', icon: 'fa fa-check-square', isUserEntry: true },
    { elementType: 'ToggleSwitch', label: 'Toggle Switch', icon: 'fa fa-toggle-on', isUserEntry: true },
    { elementType: 'ImageSelector', label: 'Image Selector', icon: 'fa fa-image', isUserEntry: true },
    { elementType: 'FileUpload', label: 'File Upload', icon: 'fa fa-upload', isUserEntry: true }
  ];

  const addElement = (elementType: ElementType) => {
    let element: QuestionSetElement = {
      id: generateTempId(),
      displayOrder: questionSet!.elements!.length || 0,
      elementType: elementType
    };

    if (ELEMENT_TYPES.find(x => x.elementType === elementType)!.skipConfig) {
      const _questionSet = { ...questionSet, ...{ elements: [...questionSet!.elements!, element] } };
      setQuestionSet(_questionSet as QuestionSet);
      return;
    }

    setEditingElement(element);
  };

  const deleteElement = (id: string) => {
    const _questionSet = { ...questionSet, ...{ elements: questionSet!.elements!.filter(x => x.id !== id) } };
    setQuestionSet(_questionSet as QuestionSet);
  }

  const completeElementEdit = (config: ElementConfig) => {
    if (editingElement?.config) {
      const _questionSet = { ...questionSet, ...{ elements: questionSet!.elements!.map(x => x === editingElement ? { ...x, ...{ config } } : x) } };
      setQuestionSet(_questionSet as QuestionSet);
    } else {
      const _questionSet = { ...questionSet, ...{ elements: [...questionSet!.elements!, { ...editingElement, ...{ config } }] } };
      setQuestionSet(_questionSet as QuestionSet);
    }
    setEditingElement(undefined);
  }

  const validate = () => {
    if (!questionSet?.name) {
      alert('Please enter a title for the question set.');
      return false;
    }

    if (!questionSet?.questionnaireType) {
      alert('Please select a questionnaire for the question set.');
      return false;
    }

    if (!questionSet.allowForAllProjectTemplates && !questionSet.projectTemplateIds?.length) {
      alert('Please select one or more project templates for the question set, or check the box to allow all.');
      return false;
    }

    if (!questionSet?.elements?.length) {
      alert('Please add at least one element to the question set.');
      return false;
    }

    return true;
  };

  const saveEdits = () => {
    if (!validate()) return;

    saveQuestionSet(questionSet!).then(questionSet => {
      if (questionSet) {
        navigate('/admin/question-sets');
      }
    });
  }

  const cancelEdits = () => {
    navigate('/admin/question-sets');
  }

  const onDragEnter = (index: number) => {
    setActiveDropzone(index);
  };

  const onDragStart = (e: DragEvent<HTMLElement>, index: number) => {
    e.dataTransfer.setData('index', index.toString());
    e.dataTransfer.setDragImage(e.currentTarget, 1, 1);
    setDraggedIndex(index);
    setActiveDropzone(index);
  };

  const onDragEnd = () => {
    setDraggedIndex(undefined);
    setActiveDropzone(undefined);
  };

  const onDrop = (e: DragEvent<HTMLElement>, newIndex: number) => {
    e.preventDefault();
    const oldIndex = parseInt(e.dataTransfer.getData('index'), 10);
    const _questionSet = { ...questionSet! };
    const [movedItem] = _questionSet.elements!.splice(oldIndex, 1);
    _questionSet.elements!.splice(newIndex, 0, movedItem);
    setQuestionSet(_questionSet);
    setDraggedIndex(undefined);
    setActiveDropzone(undefined);
  };

  if (!questionSet) return <div>Loading Question Set...</div>;

  return <div>
    <div className='row'>
      <div className="col-12">
        <div className='float-end justify-content-end'>
          <button type="button"
            onClick={() => cancelEdits()}
            className='btn btn-secondary ms-2'>
            Cancel
          </button>
          <button type="button"
            onClick={() => saveEdits()}
            className='btn btn-primary ms-2'>
            Save
          </button>
        </div>
        <h1>
          {isNewQuestionSet ? 'New Question Set' : `Question Set - ${questionSetTitle}`}
        </h1>
        <div className='clearfix'></div>
      </div>
      <div className='col-md-8 sm-mb-4'>
        <div className='question-set-viewer'>
          {!questionSet.elements?.length &&
            <div className="text-muted text-center mt-5">
              Use the Element Library to add elements to your question set...
            </div>
          }
          {questionSet.elements!.map((x, i) =>
            <div key={i}
              className={`element-wrapper draggable ${draggedIndex === i ? 'dragging' : ''} ${activeDropzone === i ? 'dropzone' : ''}`}
              draggable
              onDragEnter={() => onDragEnter(i)}
              onDragStart={(e) => onDragStart(e, i)}
              onDragEnd={onDragEnd}
              onDrop={(e) => onDrop(e, i)}
              onDragOver={(e) => e.preventDefault()}>
              <div className='element-toolbar'>
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button"
                    className="btn btn-outline-secondary drag-handle">
                    <i className='fa fa-fw fa-up-down-left-right'></i>
                  </button>
                  <button type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => setEditingElement(x)}>
                    <i className='fa fa-fw fa-pencil'></i>
                  </button>
                  <button type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => deleteElement(x.id)}>
                    <i className='fa fa-fw fa-trash'></i>
                  </button>
                </div>
              </div>
              <div className='element'>
                <ElementViewer elementType={x.elementType} config={x.config || {}} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col-md-4'>
        <Card title='About this Question Set'>
          <div className="form-group">
            <label>Title</label>
            <input type='text'
              className="form-control"
              value={questionSet.name}
              onChange={e => setQuestionSet({ ...questionSet, name: e.target.value })} />
          </div>
          <div className="form-group">
            <label>Questionnaire</label>
            <select className="form-control"
              value={questionSet.name}
              onChange={e => setQuestionSet({ ...questionSet, questionnaireType: e.target.value as QuestionnaireType })}>
              <option>{literal<QuestionnaireType>('Design Specifications')}</option>
            </select>
          </div>
          <div className='form-group'>
            <FileUploadElement config={{
              prompt: 'Image',
              acceptedFileTypes: 'image/*',
              allowMultipleSelection: false
            }}
              value={questionSet.imageUrl ? [questionSet.imageUrl] : []}
              onValueChange={x => setQuestionSet({ ...questionSet, imageUrl: x[0].content })} />
          </div>
          <hr className='mt-4' />
          <div className="form-group">
            <label>
              <input type='checkbox'
                checked={questionSet.allowForAllProjectTemplates}
                onChange={e => setQuestionSet({ ...questionSet, allowForAllProjectTemplates: e.target.checked })} />
              Allow on all project templates
            </label>
          </div>
          {
            projectTemplates && !questionSet.allowForAllProjectTemplates &&
            <div className='from-group'>
              <CheckboxListDropdown
                label='Limit to templates (Select all that apply)'
                options={projectTemplates.map(x => ({ label: x.name, value: x.id })) || []}
                values={questionSet?.projectTemplateIds || []}
                onChange={x => setQuestionSet({ ...questionSet, projectTemplateIds: x })}
                columns={1} />
            </div>
          }
          <div className="form-group">
            <label>
              <input type='checkbox'
                checked={questionSet.isRequired}
                onChange={e => setQuestionSet({ ...questionSet, isRequired: e.target.checked })} />
              Required for all projects
            </label>
          </div>
          <div className="form-group">
            <label>
              <input type='checkbox'
                checked={questionSet.allowMultipleInstances}
                onChange={e => setQuestionSet({ ...questionSet, allowMultipleInstances: e.target.checked })} />
              Allow multiple instances per project
            </label>
          </div>

          {
            params.questionSetKey &&
            <>
              <hr className='mt-4' />
              <div className='text-center mt-4'>
                <HamburgerMenu
                  type='h-ellipsis'
                  items={[
                    { label: 'Delete', iconClass: "fa fa-trash", onClick: () => onDelete() }
                  ]} />
              </div>
            </>
          }
        </Card>
        <Card title='Element Library'>
          <div className='element-library'>
            <div className='row'>
              {
                ELEMENT_TYPES.map(x => <div key={x.elementType} className='col-md-6 p-1'>
                  <button
                    className={'element-button' + (x.isUserEntry ? ' user-entry' : '')}
                    onClick={() => addElement(x.elementType)}>
                    <i className={x.icon + ' fa-fw'}></i>
                    <span>{x.label}</span>
                    <span className='clearfix'></span>
                  </button>
                </div>)
              }
            </div>
          </div>
        </Card>
      </div>
    </div>
    {
      editingElement &&
      <ElementConfigEditor
        elementType={editingElement.elementType}
        elementName={ELEMENT_TYPES.find(x => x.elementType === editingElement.elementType)!.label}
        config={editingElement.config}
        onConfigUpdated={x => completeElementEdit(x)}
        onCanceled={() => setEditingElement(undefined)} />
    }
  </div>;
}