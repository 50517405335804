import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useSearchParams } from 'react-router-dom';
import { updatePassword } from '../../api';
import { useAppContext, useDialog } from '../../hooks';
import { PasswordUpdate } from '../../models';
import './resetPassword.scss';

export const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { alert } = useDialog();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { user } = useAppContext();

    useEffect(() => {
        if (!user) {
            const code = searchParams.get('code');
            if (!code) {
                alert('Invalid reset request.');
                navigate('/');
            }    
        }
    }, [searchParams]);

    const onPasswordReset = () => {
        if (!password) {
            alert('Password is required.');
            return;
        }

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }
        const passwordReset = {
            resetCode: searchParams.get('code')!,
            password
        };

        updatePassword(passwordReset).then(() => {
            navigate('/');
        });
    }

    return <div className='reset-password-wrapper'>
        <div className='logo'>
            BLOUNT | DESIGNS
        </div>
        <div className='lead text-center mb-4'>
            OK, let's update your password...
        </div>
        <div className='login-form text-start'>
            <div className='mx-5'>
                <div className="form-group">
                    <label htmlFor="password">
                        Password
                    </label>
                    <input type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">
                        Confirm Password
                    </label>
                    <input type="password"
                        className="form-control"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)} />
                </div>
                <div className='mt-4'>
                    <button type="submit"
                        className="btn btn-primary btn-lg w-100"
                        onClick={() => onPasswordReset()}>
                        Update Password
                    </button>
                </div>
            </div>
        </div>
    </div>
};