import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { deleteProjectIntake, listProjectIntakes } from '../../../api';
import { useDialog } from '../../../hooks';
import { ProjectIntake } from '../../../models';
import { printRelativeDate } from '../../../util';
import { HamburgerMenu } from '../../common';
import './leadList.scss';

export const LeadList = () => {
    const [leads, setLeads] = useState<ProjectIntake[] | undefined>(undefined);
    const { confirm } = useDialog();

    const [search, setSearch] = useState<string>('');
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);

    const searchPattern = new RegExp(search, 'i');

    const navigate = useNavigate();

    useEffect(() => {
        const handleMessage = (event: any) => {
            if (event.data.type === 'reload') {
                setTimeout(() => listProjectIntakes({ converted: false }).then(setLeads), 1000);
            }
        };
    
        window.addEventListener('message', handleMessage);
    
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        listProjectIntakes({ converted: false }).then(setLeads);
    }, []);

    const onConvertion = (lead: ProjectIntake) => {
        navigate('/admin/projects', { state: { intake: lead } });
    };

    const onDelete = async (lead: ProjectIntake) => {
        if (await confirm('Are you sure you want to delete this lead?')) {
            setIsNonBlockingProcess(true);
            deleteProjectIntake(lead.id!).then(() => {
                setLeads(leads!.filter(x => x.id !== lead.id));
                setIsNonBlockingProcess(false);
            });
        };
    }

    if (!leads) return <div>Loading Leads...</div>;

    return <div className={isNonBlockingProcess ? 'wait' : ''}>
        <div className="float-end">
            <button type="button"
                className="btn btn-primary"
                onClick={() => window.open(window.location.origin + '/intake-form', '_blank', 'width=800,height=600')}>
                New Lead
            </button>
        </div>
        <h1>Active Leads</h1>
        <div className="clearfix"></div>
        {
            leads?.length === 0 &&
            <div className='box mt-4 text-center'>
                There are no active leads at this time.
            </div>
        }
        {
            leads?.length > 0 &&
            <>
                <div className='my-4 col-md-4'>
                    <div className="input-group">
                        <input type="text"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={e => setSearch(e.target.value)} />
                        {
                            !!search && <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setSearch('')}>
                                <i className='fa fa-times'></i>
                            </button>
                        }
                    </div>
                </div>
                {/* HACK: the padding is a hack to prevent dropdowns from clipping */}
                <div className='table-responsive' style={{ paddingBottom: !!search ? '15rem' : '0' }}>
                    <table className='table table-striped nowrap-headers nowrap-columns'>
                        <thead>
                            <tr>
                                <th>Inquiry Date</th>
                                <th>Contact Name</th>
                                <th>Address</th>
                                <th className='text-center'>
                                    New Construction
                                </th>
                                <th>Contact Info</th>
                                <th className='text-center'>Attachments</th>
                                <th style={{ width: 0 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leads.filter(x => !search || x.primaryContact.name?.match(searchPattern) || x.primaryContact.company?.match(searchPattern) || x.address.street.match(searchPattern))
                                    .map(lead => {
                                        return <tr key={lead.id}>
                                            <td>{printRelativeDate(lead.submissionDate)}</td>
                                            <td>
                                                <a href={lead.webUrl}
                                                    title="View"
                                                    target='_blank' rel="noreferrer">
                                                    {lead.primaryContact.name}
                                                </a>
                                                {
                                                    lead.primaryContact.company && <>
                                                        <br />
                                                        <small>{lead.primaryContact.company}</small>
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {lead.address.street}<br />
                                                {lead.address.city}, {lead.address.state} {lead.address.postal}
                                            </td>
                                            <td className='text-center'>
                                                {lead.isNewConstruction ? 'Yes' : 'No'}
                                            </td>
                                            <td>
                                                E: {lead.primaryContact.email || 'N/A'}<br />
                                                P: {lead.primaryContact.phone || 'N/A'}
                                            </td>
                                            <td className='text-center'>
                                                { lead.attachements?.length === 0 && "-" }
                                                {
                                                    lead.attachements?.length > 0 &&
                                                    <ul>
                                                        {
                                                            lead.attachements.map((attachment, index) => {
                                                                return <li key={index}>
                                                                    {attachment.name}
                                                                </li>;
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </td>
                                            <td className='text-end'>
                                                <HamburgerMenu type={'v-ellipsis'} size='sm' items={[
                                                    { label: 'View', iconClass: 'fa fa-fw fa-eye', onClick: () => window.open(lead.webUrl) },
                                                    { label: 'Convert to Project', iconClass: 'fa fa-fw fa-arrow-right', onClick: () => onConvertion(lead) },
                                                    { label: 'Delete', iconClass: 'fa fa-fw fa-trash', onClick: () => onDelete(lead) },
                                                ]} />
                                            </td>
                                        </tr>;
                                    })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        }
    </div>;
}