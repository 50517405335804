import { useEffect, useState } from 'react';
import { useAppContext, useDialog } from '../../../hooks';
import { ProjectTabProps } from '../projectViewer';
import { Project, ProjectStep, ProjectTemplate } from '../../../models';
import { completeProject, getProjectTemplate, saveProject } from '../../../api';
import { Step, Stepper } from '../../common/stepper';
import { plural } from 'pluralize';

export const DashboardTab = ({ project, setProject }: ProjectTabProps) => {
    const { user } = useAppContext();
    const [projectTemplate, setProjectTemplate] = useState<ProjectTemplate>();
    const [allStepsComplete, setAllStepsComplete] = useState(false);

    const { confirm } = useDialog();

    useEffect(() => {
        if (project && project.templateId !== projectTemplate?.id) {
            getProjectTemplate(project.templateId).then(setProjectTemplate);
        }
    }, [project]);

    useEffect(() => {
        if (project && projectTemplate) {
            const remainingSteps = projectTemplate.steps.filter(step => !project.completedStepIds.includes(step.id));
            setAllStepsComplete(remainingSteps.length === 0);
        }
    }, [project, projectTemplate]);

    const goToTab = (tabName: string) => {
        return (document.querySelector(`.nav-tabs a#${tabName}-tab`) as any)?.click();
    }

    if (!projectTemplate) {
        return <>Loading...</>;
    }

    const completedStepNumbers = Object.values(projectTemplate.steps)
        .filter(step => project.completedStepIds.includes(step.id))
        .map(step => step.number);

    const nextStepNumber = completedStepNumbers.length === projectTemplate.steps.length
        ? -1
        : completedStepNumbers.length > 0
            ? Math.max(...completedStepNumbers) + 1
            : 1;

    const convertStep = (projectStep: ProjectStep): Step => {
        const getStatus = () => {
            if (project.status === 'Completed' || completedStepNumbers.includes(projectStep.number)) {
                return 'done';
            }

            if (projectStep.number > nextStepNumber) {
                return 'pending';
            }

            if (projectStep.number < nextStepNumber && projectStep.trigger === undefined) {
                return 'skipped';
            }

            return 'active';
        };

        const getIsBlocked = () => {
            if (projectStep.trigger === undefined && !projectStep.allowClientConfirmation && !user?.roles.includes('admin')) {
                return true;
            }
            if (projectStep.trigger === 'Assets Approved') {
                // check to see if approval is blocked by unposted assets
                let assetCount = 0, approvedAssetCount = 0;
                if (projectStep.assetType === 'Design') {
                    assetCount = project.designCount || 0;
                    approvedAssetCount = project.approvedDesignCount || 0;
                } else if (projectStep.assetType === 'Material Selection') {
                    assetCount = project.materialSelectionCount || 0;
                    approvedAssetCount = project.approvedMaterialSelectionCount || 0;
                } else if (projectStep.assetType === 'Bid') {
                    assetCount = project.bidCount || 0;
                    approvedAssetCount = project.approvedBidCount || 0;
                }

                if (approvedAssetCount === assetCount) {
                    return true;
                }
            }

            return false;
        };

        const getActionButtonProps = () => {
            switch (projectStep.trigger) {
                case 'Questionnaire Submitted':
                    return {
                        text: 'Complete Online',
                        onClick: () => goToTab('Specifications')
                    };
                case 'Assets Approved':
                    return {
                        text: 'Review ' + plural(projectStep.assetType!),
                        onClick: () => {
                            goToTab({
                                'Design': 'Designs',
                                'Material Selection': 'Materials Selections',
                                'Bid': 'Bids'
                            }[projectStep.assetType!]);
                        }
                    }
                case undefined:
                    return {
                        text: 'Mark Complete',
                        onClick: () => {
                            let _project = { ...project };
                            _project.completedStepIds = [..._project.completedStepIds, projectStep.id];
                            saveProject(_project).then(async project => {
                                setProject(project);
                                if (user?.roles.includes('admin') && project.status === 'In Progress') {
                                    const remainingSteps = projectTemplate.steps.filter(step => !project.completedStepIds.includes(step.id));
                                    if (remainingSteps.length === 0) {
                                        if (await confirm('All steps have been completed. Would you like to mark this project as completed?')) {
                                            completeProject(project.id).then(setProject);
                                        }
                                    }
                                }
                            });
                        }
                    };
                default:
                    return undefined;
            }
        }

        return {
            number: projectStep.number,
            name: projectStep.name,
            description: projectStep.description,
            status: getStatus(),
            isBlocked: getIsBlocked(),
            actionButton: getActionButtonProps()
        };
    }

    const onCompleteProject = async (project: Project) => {
        if (await confirm('Are you sure you want to complete this project?')) {
            completeProject(project.id).then(setProject);
        }
    };

    return <>
        <h1>Welcome, {user?.firstName}!</h1>
        <p
            dangerouslySetInnerHTML={{
                __html: projectTemplate.introductionText?.replace(/\r?\n|\r/g, '<br/>'),
            }}
        />

        <div className='box steps inverse mt-4'>
            <div className="box-wrapper">
                <h2 className='process-header mt-2'>
                    The Process
                </h2>
                <div className='mb-4'>&nbsp;</div>
                <Stepper
                    steps={projectTemplate.steps.map(convertStep)} />
                {/* {
                    allStepsComplete &&
                    <div className='text-center mt-2 text-light'>
                        <h3>Congradulations! All steps are complete!</h3>
                    </div>
                } */}
                {
                    allStepsComplete && user?.roles.includes('admin') && project.status === 'In Progress' &&
                    <div className="text-center mt-4">
                        <button className='btn btn-success' onClick={() => onCompleteProject(project)}>
                            Complete Project
                        </button>
                    </div>
                }
            </div>
        </div>
    </>
};
