import { DragEvent, useEffect, useState } from 'react';
import './projectTemplateList.scss';
import { deleteProjectTemplate, listProjectTemplates, saveProjectTemplate } from '../../../api';
import { ProjectStepTrigger, AssetType, ProjectStep, ProjectTemplate, QuestionnaireType } from '../../../models';
import { generateTempId, literal, printRelativeDate } from '../../../util';
import { useDialog } from '../../../hooks';
import { Blocker, Card, CheckboxListDropdown, Drawer, HamburgerMenu, Modal } from '../../common';
import pluralize from 'pluralize';

export const ProjectTemplateList = () => {
    const [projectTemplates, setProjectTemplates] = useState<ProjectTemplate[] | undefined>(undefined);

    const [editingProjectTemplate, setEditingProjectTemplate] = useState<ProjectTemplate | undefined>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isNonBlockingProcess, setIsNonBlockingProcess] = useState<boolean>(false);

    const { confirm } = useDialog();

    useEffect(() => {
        listProjectTemplates().then(setProjectTemplates);
    }, []);

    const generateNewTemplate = (): ProjectTemplate => ({
        id: generateTempId(),
        key: undefined,
        name: '',
        description: '',
        introductionText: '',
        questionnaires: [
            literal<QuestionnaireType>('Design Specifications')
        ],
        steps: []
    });

    const completeProjectTemplateEdit = () => {
        setIsSaving(true);
        saveProjectTemplate(editingProjectTemplate!).then((template) => {
            if (template) {
                const _projectTemplates = [...projectTemplates!];
                const index = _projectTemplates.findIndex(x => x.id === template!.id);
                if (index > -1) {
                    _projectTemplates[index] = template;
                } else {
                    _projectTemplates.push(template!);
                }
                setProjectTemplates(_projectTemplates);
                setEditingProjectTemplate(undefined);
            }
            setIsSaving(false);
        });
    }

    const onDelete = async () => {
        if (await confirm('Are you sure you want to delete this template?')) {
            deleteProjectTemplate(editingProjectTemplate!.id).then(() => {
                setProjectTemplates(projectTemplates!.filter(x => x.id !== editingProjectTemplate!.id));
                setEditingProjectTemplate(undefined);
            });
        };
    }

    if (!projectTemplates) {
        return <div>Loading Project Templates...</div>;
    }

    return <div className={isNonBlockingProcess ? 'wait' : ''}>
        <div className="float-end">
            <button type="button"
                className="btn btn-primary"
                onClick={() => setEditingProjectTemplate(generateNewTemplate())}>
                New Template
            </button>
        </div>
        <h1>Project Templates</h1>
        <div className="clearfix"></div>
        {
            projectTemplates?.length === 0 &&
            <div className='box mt-4 text-center'>
                There are no project templates yet. Click the "New Project Template" button to get started.
            </div>
        }
        <div className="row">
            {
                projectTemplates?.map((x, i) =>
                    <div className='col-md-3'>
                        <ProjectTemplateCard key={i} template={x} onEdit={setEditingProjectTemplate} />
                    </div>)
            }
        </div>
        {
            editingProjectTemplate &&
            <ProjectTemplateEditor
                template={editingProjectTemplate}
                setTemplate={setEditingProjectTemplate}
                onSave={() => completeProjectTemplateEdit()}
                onDelete={() => onDelete()}
                onCancel={() => setEditingProjectTemplate(undefined)}
                isSaving={isSaving} />
        }
    </div>;
};

interface ProjectTemplateCardProps {
    template: ProjectTemplate;
    onEdit: (projectTemplate: ProjectTemplate) => void;
}

const ProjectTemplateCard = ({ template, onEdit }: ProjectTemplateCardProps) =>
    <Card key={template.id} title={template.name} iconClass='fa fa-clone'>
        <div className='row'>
            <div className='col-md-8 text-sm text-muted fs-6'>
                Steps: {template.steps.length || 0}<br />
                Projects: {template.projectCount || 0}<br />
                Updated: {printRelativeDate(template.lastUpdatedOn)}
            </div>
            <div className='col-md-4 text-end'>
                <button className='btn btn-sm btn-outline-primary' onClick={() => onEdit(template)}>
                    <i className='fa fa-pencil fa-fw'></i>
                </button>
            </div>
        </div>
    </Card>;

interface ProjectTemplateEditorProps {
    template: ProjectTemplate;
    setTemplate: (projectTemplate: ProjectTemplate) => void;
    onSave: () => void;
    onDelete: () => void;
    onCancel: () => void;
    isSaving?: boolean;
}

const ProjectTemplateEditor = ({ template, setTemplate, onSave, onDelete, onCancel, isSaving }: ProjectTemplateEditorProps) => {
    const [editingStep, setEditingStep] = useState<ProjectStep | undefined>(undefined);
    const [draggedIndex, setDraggedIndex] = useState<number | undefined>(undefined);
    const [activeDropzone, setActiveDropzone] = useState<number | undefined>(undefined);

    const { alert } = useDialog();

    const generateNewStep = (): ProjectStep => ({
        id: generateTempId(),
        number: template.steps.length + 1,
        name: '',
        description: '',
        allowClientConfirmation: true
    });

    const onDragEnter = (index: number) => {
        setActiveDropzone(index);
    };

    const onDragStart = (e: DragEvent<HTMLElement>, index: number) => {
        e.dataTransfer.setData('index', index.toString());
        e.dataTransfer.setDragImage(e.currentTarget, 1, 1);
        setDraggedIndex(index);
        setActiveDropzone(index);
    };

    const onDragEnd = () => {
        setDraggedIndex(undefined);
        setActiveDropzone(undefined);
    };

    const onDrop = (e: DragEvent<HTMLElement>, newIndex: number) => {
        e.preventDefault();
        const oldIndex = parseInt(e.dataTransfer.getData('index'), 10);
        const _template = { ...template };
        const [movedItem] = _template.steps.splice(oldIndex, 1);
        _template.steps.splice(newIndex, 0, movedItem);
        _template.steps.forEach((x, i) => x.number = i + 1);
        setTemplate(_template);
        setDraggedIndex(undefined);
        setActiveDropzone(undefined);
    };

    const validate = () => {
        if (!template.name) {
            alert('Please enter a name for the template.');
            return false;
        } else if (!template.steps.length) {
            alert('Please add at least one step to the template.');
            return false;
        }
        return true;
    };

    return <Drawer title='Project Template Editor' size='lg'
        confirmText='Save'
        onConfirm={() => {
            if (validate()) {
                onSave();
            }
        }}
        onCancel={() => onCancel()}>
        <Blocker block={isSaving}>
            <div className="form-group">
                <label>Name</label>
                <input type='text'
                    className="form-control"
                    value={template.name}
                    onChange={e => setTemplate({ ...template, name: e.target.value })} />
            </div>
            <div className="form-group">
                <label>Description</label>
                <input type='text'
                    className="form-control"
                    value={template.description}
                    onChange={e => setTemplate({ ...template, description: e.target.value })} />
            </div>
            <div className="form-group">
                <label>Introduction Text</label>
                <textarea rows={3}
                    className="form-control"
                    value={template.introductionText}
                    onChange={e => setTemplate({ ...template, introductionText: e.target.value })} />
            </div>
            <div className='from-group'>
                <CheckboxListDropdown
                    label='Quesionnaire(s)'
                    options={[
                        { value: literal<QuestionnaireType>('Design Specifications'), label: literal<QuestionnaireType>('Design Specifications') }
                    ]}
                    values={template.questionnaires || []}
                    onChange={x => setTemplate({ ...template, questionnaires: x as QuestionnaireType[] })}
                    columns={1} />
            </div>
            <hr className='mb-4' />
            <Card title='Steps'>
                <ul className="list-group">
                    {
                        template.steps.map((step, i) =>
                            <li key={i}
                                className={`list-group-item draggable ${draggedIndex === i ? 'dragging' : ''} ${activeDropzone === i ? 'dropzone' : ''}`}
                                draggable
                                onDragEnter={() => onDragEnter(i)}
                                onDragStart={(e) => onDragStart(e, i)}
                                onDragEnd={onDragEnd}
                                onDrop={(e) => onDrop(e, i)}
                                onDragOver={(e) => e.preventDefault()}>
                                <div className='container p-0 d-flex align-items-center'>
                                    <div className='col-auto'>
                                        <i className='drag-handle fa fa-grip-vertical me-3'></i>
                                    </div>
                                    <div className='col-auto flex-grow-1'>
                                        <div className='text-sm'>
                                            <span className="fa fa-fw me-1">{step.number}</span>
                                            <span>{step.name}</span>
                                        </div>
                                        <div className='text-sm text-muted'>
                                            {step.trigger && <>
                                                <i className="fa-regular fa-fw fa-circle-check me-1"></i>
                                                Automatic -
                                                {step.trigger === 'Questionnaire Submitted' && `${step.questionnaireType} Submitted)`}
                                                {step.trigger === 'Assets Posted' && `${pluralize(step.assetType as string)} Posted`}
                                                {step.trigger === 'Assets Approved' && `All ${pluralize(step.assetType as string)} Approved`}
                                            </>}
                                            {!step.trigger && <>
                                                <i className="fa-regular fa-fw fa-circle-user me-1"></i>
                                                Manual -
                                                {step.allowClientConfirmation && 'Admin or Client Confirmation'}
                                                {!step.allowClientConfirmation && 'Admin Confirmation'}
                                            </>}
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        <HamburgerMenu size='xs' type='v-ellipsis' items={[
                                            { label: 'Edit', iconClass: 'fa fa-pencil', onClick: () => setEditingStep(step) },
                                            {
                                                label: 'Delete', iconClass: 'fa fa-trash', onClick: () => {
                                                    const _template = { ...template };
                                                    _template.steps = _template.steps.filter(x => x.id !== step.id);
                                                    setTemplate(_template);
                                                }
                                            }
                                        ]} />
                                    </div>
                                </div>
                            </li>)
                    }
                    <button className='btn btn-primary mt-3' onClick={() => setEditingStep(generateNewStep())}>
                        Add Step
                    </button>
                </ul>
            </Card>
            <div className="form-group">
                <label>
                    <input type='checkbox'
                        className="form-check-input"
                        checked={template.autoCompleteProjectOnLastStep}
                        onChange={e => setTemplate({ ...template, autoCompleteProjectOnLastStep: e.target.checked })} />
                    Auto-complete project after last step
                </label>
            </div>
            {
                template.id && !template.id.startsWith("_") && !template.projectCount &&
                <div className='text-center'>
                    <HamburgerMenu size='sm' type='h-ellipsis' items={[
                        { label: 'Delete Template', iconClass: 'fa fa-trash', onClick: () => onDelete() },
                    ]} />
                </div>
            }
        </Blocker>
        {
            editingStep &&
            <Modal title='Project Step Editor'
                confirmText='Save'
                onConfirm={() => {
                    // Validate
                    if (!editingStep.name) {
                        alert('Please enter a name for the step.');
                        return;
                    } else if (editingStep.trigger === 'Questionnaire Submitted' && !editingStep.questionnaireType) {
                        alert('Please select a questionnaire for the completion trigger.');
                        return;
                    } else if ((editingStep.trigger === 'Assets Posted' || editingStep.trigger === 'Assets Approved') && !editingStep.assetType) {
                        alert('Please select an asset type for the completion trigger.');
                        return;
                    }

                    const _template = { ...template };
                    const index = _template.steps.findIndex(x => x.id === editingStep.id);
                    if (index > -1) {
                        _template.steps[index] = editingStep;
                    } else {
                        _template.steps.push(editingStep);
                    }
                    setTemplate(_template);
                    setEditingStep(undefined);
                }}
                onCancel={() => setEditingStep(undefined)}>
                <div className="form-group">
                    <label>Name</label>
                    <input type='text'
                        className="form-control"
                        value={editingStep.name}
                        onChange={e => setEditingStep({ ...editingStep, name: e.target.value })} />
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Completion Trigger</label>
                            <select
                                className="form-control"
                                value={editingStep.trigger}
                                onChange={e => setEditingStep({ ...editingStep, trigger: (e.target.value || undefined) as ProjectStepTrigger })}>
                                <option value={''}>Manual Confirmation</option>
                                {
                                    !!template.questionnaires?.length &&
                                    <option>{literal<ProjectStepTrigger>('Questionnaire Submitted')}</option>
                                }
                                <option>{literal<ProjectStepTrigger>('Assets Posted')}</option>
                                <option>{literal<ProjectStepTrigger>('Assets Approved')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            !editingStep.trigger &&
                            <div className="form-group">
                                <br />
                                <label className="form-check-label">
                                    <input type="checkbox"
                                        className="form-check-input"
                                        checked={editingStep.allowClientConfirmation}
                                        onChange={e => setEditingStep({ ...editingStep, allowClientConfirmation: e.target.checked })} />
                                    Allow Client Confirmation
                                </label>
                            </div>
                        }
                        {
                            editingStep.trigger === 'Questionnaire Submitted' &&
                            <div className="form-group">
                                <label>Questionnaire</label>
                                <select
                                    className="form-control"
                                    value={editingStep.questionnaireType}
                                    onChange={e => setEditingStep({ ...editingStep, questionnaireType: (e.target.value || undefined) as QuestionnaireType })}>
                                    <option value={''}></option>
                                    {
                                        template.questionnaires?.map(x => <option key={x} value={x}>{x}</option>)
                                    }
                                </select>
                            </div>
                        }
                        {
                            (editingStep.trigger === 'Assets Posted' || editingStep.trigger === 'Assets Approved') &&
                            <div className="form-group">
                                <label>Asset Type</label>
                                <select
                                    className="form-control"
                                    value={editingStep.assetType}
                                    onChange={e => setEditingStep({ ...editingStep, assetType: (e.target.value || undefined) as AssetType })}>
                                    <option value={''}></option>
                                    <option>{literal<AssetType>('Design')}</option>
                                    <option>{literal<AssetType>('Bid')}</option>
                                    <option>{literal<AssetType>('Material Selection')}</option>
                                </select>
                            </div>
                        }
                    </div>
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea rows={7}
                        className="form-control"
                        value={editingStep.description}
                        onChange={e => setEditingStep({ ...editingStep, description: e.target.value })} />
                </div>
            </Modal>
        }
    </Drawer>;
}
