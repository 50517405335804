import { Link, Outlet } from 'react-router-dom';
import './appWrapper.scss';
import { HamburgerMenu, MenuItem } from '../common';
import { useAppContext } from '../../hooks';
import { useEffect, useState } from 'react';

export const AppWrapper = () => {
  const { user, logout } = useAppContext();
  const [menu, setMenu] = useState<MenuItem[]>([]);

  useEffect(() => {
    let _menu: any[] = [];
    if (user) {
      if (user.roles.includes('admin')) {
        _menu.push(...[
          { label: 'Projects', path: '/admin/projects' },
          { label: 'Leads', path: '/admin/leads' },
          { type: 'divider' },
          { label: 'Project Templates', path: '/admin/project-templates' },
          { label: 'Materials', path: '/admin/materials' },
          { label: 'Question Sets', path: '/admin/question-sets' },
          { label: 'Configuration', path: '/admin/config' },
          { type: 'divider' }
        ]);
      } else if (user.projects?.length) {
        _menu.push(...user.projects.map(p => (
          { label: `${p.description.trim() || p.ownerName} | ${p.address.street}`, path: `/${p.key}` }
        )), { type: 'divider' });
      }
      _menu.push({ label: 'Logout', iconClass: "fa fa-arrow-right-from-bracket", onClick: () => { logout() } });
    }
    setMenu(_menu);
  }, [user]);

  return <div className='page-wrapper'>
    <header>
      <div>
        <Link to="/" className='logo'>
          BLOUNT | DESIGNS
        </Link>
        {user?.roles.includes("admin") && <span className='admin-badge ms-2'>ADMIN</span>}
      </div>
      <HamburgerMenu type='hamburger' header={user?.roles.includes("admin") ? `${user?.fullName} (Admin)` : user?.fullName} items={menu} />
    </header>
    <main>
      <Outlet />
    </main>
  </div>
}

