import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import './acceptInvite.scss';
import { useSearchParams } from 'react-router-dom';
import { createUserFromInvitation, getUserInvitation, login } from '../../api';
import { TextEntryElement } from '../shared';
import { Card } from '../common';
import { NewUserFromInvite } from '../../models';
import { useAppContext, useDialog } from '../../hooks';

export const AcceptInvite = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { alert } = useDialog();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { user, setUser } = useAppContext();

    useEffect(() => {
        const code = searchParams.get('code');
        if (code) {
            getUserInvitation(code).then(invite => {
                if (invite) {
                    if (!user && !invite.isExistingUser) {
                        // Lets setup this account
                        setEmail(invite.email);
                    } else if (user && user.email !== invite.email) {
                        // Invite not intended for this logged in user, regardless of whether the invite is for an existing user or not
                        alert('This invitation was intended for another user. Please logout and try the link again.');
                        navigate('/');
                    } else {
                        // Invite was intended for this user and there is nothing to do because they already have an account
                        // OR invite is for an existing user but they need to login ('/' will automatically redirect to '/login')
                        navigate('/');
                    }
                } else {
                    alert('Invalid invitation.');
                    navigate('/');
                }
            });
        } else {
            alert('Invalid invitation.');
            navigate('/');
        }

    }, [searchParams]);

    const createAccount = () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }
        const userFromInvite: NewUserFromInvite = {
            inviteCode: searchParams.get('code')!,
            firstName,
            lastName,
            password
        };

        createUserFromInvitation(userFromInvite).then(user => {
            if (user) {
                login({ userName: email, password }).then(() => {
                    setUser(user);
                    navigate('/');
                });
            }
        });
    }

    if (!email) {
        return <></>
    }

    return <div className='accept-invite-wrapper'>
        <div className='logo'>
            BLOUNT | DESIGNS
        </div>
        <div className='lead text-center mb-4'>
            Welcome! Before we get started, let's get your account setup...
        </div>
        <div className='login-form text-start'>
            <Card title='Profile Information'>
                <div className="row">
                    <div className="col-md-5">
                        <TextEntryElement
                            config={{ prompt: 'First Name' }}
                            value={firstName}
                            onValueChange={setFirstName} />
                    </div>
                    <div className="col-md-7">
                        <TextEntryElement
                            config={{ prompt: 'Last Name' }}
                            value={lastName}
                            onValueChange={setLastName} />
                    </div>
                </div>
                <TextEntryElement
                    config={{ prompt: 'Email' }}
                    value={email}
                    isDisabled={true} />
            </Card>
            <Card title="Password">
                <div className="form-group">
                    <label htmlFor="password">
                        Password
                    </label>
                    <input type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password">
                        Confirm Password
                    </label>
                    <input type="password"
                        className="form-control"
                        id="confirm-password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)} />
                </div>
            </Card>
            <div className='mt-4'>
                <button type="button"
                    className="btn btn-primary btn-lg w-100"
                    onClick={() => createAccount()}>
                    Create Account
                </button>
            </div>
        </div>
    </div>
};